import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {LanguageService} from "../language/LanguageService";
import {AccountService} from "../repositories/AccountService";
import {CategoryRepository, ICategory} from "../repositories/category/CategoryRepository";
import {emptySocialSignupInfo, LoginService, SocialSignupInfo} from "../login/LoginService";
import {NotificationService} from "../repositories/NotificationService";
import styled from "styled-components";
import {fontFamilyMedium, fontFamilyRegular} from "../style/fonts";
import {connect} from "react-redux";
import {setExistSsqAccountNotSnsStore} from "../store/reducers/rootReducer";
import {LOGIN_TYPE_GOOGLE, LOGIN_TYPE_KAKAO, LOGIN_TYPE_NAVER} from "../definitions/LoginType";
import CategoryStore from "../repositories/category/CategoryStore";
import {CategoryType} from "../definitions/CategoryType";
import {LocalStorageWrapper} from "../util/LocalStorageWrapper";
import {set_headercmd} from "../conf/cmder";
import axios from "axios";
import {IfLoginInfo} from "models/IfLoginInfo";
import {ACTION_TYPES} from "store/ActionTypes";
import {LoadingLayer} from "ctls/modal/LoadingLayer";
import {apiutil} from "util/apiutil";
import {LANG_EN, LANG_EN_US, LANG_KO_KR, langutil} from "language/langutil";
import {loginutil} from "util/loginutil";
import {ssqutil} from "util/ssqutil";
import {UserProfileImage} from "header/userprofile/UserProfileImage";
import {UserProfileBtn} from "header/userprofile/UserProfileBtn";
import {isEqual} from "lodash-es";
import {i18ntrans} from "language/i18nkey";
import clsx from 'clsx';
import {eventBus} from "util/eventbus";
import {AllPopupWrap} from "footer/Footer";
//import {ManageMyInfoPopup} from "header/ManageMyInfoPopup";
import {roleutil} from "util/roleutil";
import {DisplayPopupItem, IfDisplayPopupItem} from "models/IfDisplayPopupItem";
import {AllPopupOverlay, TranPopupOverlay} from "style/CommonStyledComponents";

const ManageMyInfoPopup = React.lazy(() => import("../header/ManageMyInfoPopup").then(module => ({default: module.ManageMyInfoPopup})));
const LoginFailPopup = React.lazy(() => import("./LoginFailPopup"));

const TermsDetailPopup = React.lazy(() => import( "./TermsDetailPopup").then(module => ({default: module.TermsDetailPopup})));
const SignupPopup = React.lazy(() => import( "./SignupPopup").then(module => ({default: module.SignupPopup})));
const LoginPopup = React.lazy(() => import( "./LoginPopup").then(module => ({default: module.LoginPopup})));
const SendVerifyEmailPopup = React.lazy(() => import( "./SendVerifyEmailPopup").then(module => ({default: module.SendVerifyEmailPopup})));

const SignupSocialPopup = React.lazy(() => import( "./SignupSocialPopup").then(module => ({default: module.SignupSocialPopup})));
const PasswordResetPopup = React.lazy(() => import( "./PasswordResetPopup").then(module => ({default: module.PasswordResetPopup})));
const SendPasswordResetEmailPopup = React.lazy(() => import( "./SendPasswordResetEmailPopup").then(module => ({default: module.SendPasswordResetEmailPopup})));
const TermsDetailPrivatePopup = React.lazy(() => import( "./TermsDetailPrivatePopup").then(module => ({default: module.TermsDetailPrivatePopup})));

const IdeaEventPopup = React.lazy(() => import( "../borispage/event/IdeaEventPopup").then(module => ({default: module.IdeaEventPopup})));

export interface LoginInputValues {
  id: string;
  password: string;
}

interface IHeaderMatchParams {
  country?: string;
  lang?: string;
}

interface Props extends RouteComponentProps<IHeaderMatchParams, any, any> {
  languageService: LanguageService;
  accountService: AccountService;
  notificationService: NotificationService;
  categoryRepository: CategoryRepository;
  categoryStore: CategoryStore;
  onClickSearchButton: Function;
  loginService: LoginService;
  isLoginPopup: boolean;
  isLoginFailPopup: boolean;
  isExistSsqAccountNotSnsStore: boolean;
  localStorageWrapper: LocalStorageWrapper;
  setExistSsqAccountNotSnsStore;
  setLoginInfo: (info: IfLoginInfo) => void;
  setShowLoginPopup: (b: boolean) => void;
  loginInfo: IfLoginInfo;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  closeSearchPopup: Function;
  showSearchBoxPopup: boolean;
  showLoginPopup: boolean;
  setCountryLang: (country: string, lang: string) => void;
  hideHeader?: boolean;
  setDisplayPopupItem: (b: IfDisplayPopupItem) => void;
  setDisplayBottomPopup: (b: boolean) => void;
}

interface State {
  showUserPopup: boolean
  isLoadingUserPopup: boolean
  countrySelect: boolean
  //showLoginPopup: boolean
  showLoginFailPopup: boolean
  showSignupPopup: boolean
  showSignupSocialPopup: boolean
  termsType: string
  showTermsDetailPopup: boolean
  showTermsDetailPrivatePopup: boolean
  showOriginalVersionTermsDetailPopup: boolean
  showSendVerifyEmailPopup: boolean
  showPasswordResetPopup: boolean
  showSendPasswordResetEmailPopup: boolean
  loginInputValues: LoginInputValues
  signupEmail: string
  resetPasswordEmail: string
  socialSignupInfo: SocialSignupInfo
  productCategories: ICategory[],
  industrySolutionCategories: ICategory[],
  applicationSolutionCategories: ICategory[],
  ProductMenu: boolean
  SolutionMenu: boolean
  CommunityMenu: boolean
  ServiceMenu: boolean
  alertNewAlarm: boolean
  alertAlarmCount: number
  //avatar: string
  //techPoint: number
  langSelect: boolean
  selectedHeader: string;
  showMobileCommunityMenuPopup: boolean
  showManageMyInfoPopup: boolean
  isPartner: boolean
}

let intervalId;

class Header extends Component<Props, State> {
  // getLoginInfo = debounce(() => {
  //   axios.get('/api/guest/loginInfo').then(res => {
  //     this.props.setLoginInfo(res.data);
  //   }).catch(err => {
  //     console.error("E", err);
  //   });
  // }, 200);
  private readonly REFRESH_IS_ADMIN_CHECK_TIME = 3000;


  //private readonly EXPIRED_EVENT_TIME = new Date('2022-08-01T00:00:00');

  constructor(props: Props) {
    super(props);

    this.props.setCountryLang(this.props.match?.params?.country, this.props.match?.params?.lang);

    this.state = {
      showUserPopup: false,
      showMobileCommunityMenuPopup: false,
      isLoadingUserPopup: false,
      countrySelect: false,
      showLoginFailPopup: false,
      showSignupPopup: false,
      showSignupSocialPopup: false,
      termsType: "",
      showTermsDetailPopup: false,
      showTermsDetailPrivatePopup: false,
      showOriginalVersionTermsDetailPopup: false,
      showSendVerifyEmailPopup: false,
      showPasswordResetPopup: false,
      showSendPasswordResetEmailPopup: false,
      loginInputValues: {id: "", password: "",},
      signupEmail: "",
      resetPasswordEmail: "",
      socialSignupInfo: emptySocialSignupInfo,
      productCategories: [],
      industrySolutionCategories: [],
      applicationSolutionCategories: [],
      ProductMenu: false,
      SolutionMenu: false,
      CommunityMenu: false,
      ServiceMenu: false,
      alertNewAlarm: false,
      alertAlarmCount: 0,
      //avatar: "",
      //techPoint: 0,
      langSelect: false,
      selectedHeader: '',
      showManageMyInfoPopup: false,
      isPartner: false
    };

    //ssqdebugutil.addobj("Header", this);
  }

  getLoginInfoNow() {
    axios.get('/api/guest/loginInfo').then(res => {

      if (!isEqual(res.data, this.props.loginInfo)) {
        this.props.setLoginInfo(res.data);
      }
    }).catch(err => {
      console.error("E", err);
    });
  }

  headercmd(cmd: string, p1?, p2?, data?) {
    console.log("cmd : ", cmd, ", p1 : ", p1, " , p2 : ", p2, " , data : ", data);
    if (p1 === 'store') {
      this.props.setExistSsqAccountNotSnsStore(p2);
    }
  }

  headerevent(cmd: string, data?) {
    if (cmd === 'logininfo') {
      this.getLoginInfoNow();
    } else if (cmd === 'login-popup') {
      this.props.setShowLoginPopup(true);
      this.setState({showUserPopup: false});
    }
  }


  async componentDidMount() {
    eventBus.$on('headerevent', this.headerevent.bind(this));
    this.selectedHeader();
    set_headercmd(this.headercmd.bind(this));
    const info: IfLoginInfo = (await axios.get('/api/guest/loginInfo'))?.data;
    if (info) {
      if (!isEqual(info, this.props.loginInfo)) {
        this.props.setLoginInfo(info);
        if (loginutil.islogin(info)) {
          await this.checkNewAlarmCount(info.userId);
          roleutil.hasPartnerChecks(info.userId, this.props.languageService.lang()).then(res => {
            if (res == true) this.setState({isPartner: res});
          });
        }
      }
    }
    await this.loadAllCategories();
    await this.getAllSolutionCategories();

    let productCategories = this.getCurrentLevelCategories(1, null);
    this.setState({productCategories});

    const isExistSsqAccountNotSnsStore = this.props.isExistSsqAccountNotSnsStore;

    if (isExistSsqAccountNotSnsStore) {
      const loginInputValues: LoginInputValues = {
        id: this.props.history.location.state.email,
        password: this.props.history.location.state.socialId
      };
      this.showLoginFailPopupFunc(true, loginInputValues);
    }

    if (await this.isNeedSocialSignupFromRequireLoginPage()) {
      this.showSocialSignupPopupFunc();
    }

    if (this.isBrowserIE()) {
      location.replace('/unsupportedBrowserPage');
      //alert(this.getTranslation("I18N_BROWSER_CHECK_ALERT"))
    }


    console.log("create interval before", intervalId);
    if (intervalId) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(() => {
        if (this.props.loginInfo?.userId) return;
        //console.log("header login info ", this.props.loginInfo);
        axios.get('/api/guest/loginInfo').then(res => {
          const info: IfLoginInfo = res.data;
          if (!isEqual(info, this.props.loginInfo)) {
            this.props.setLoginInfo(info);
            this.props.setDisplayPopupItem({...new DisplayPopupItem(), center: true, asset: true});
            this.props.setDisplayBottomPopup(false);
            this.props.setDisplayBottomPopup(true);
            if (info?.userId) {
              this.checkNewAlarmCount(info.userId);
            }
          }
        }).catch(e => console.error("E", e));
      },
      this.REFRESH_IS_ADMIN_CHECK_TIME
    );
    console.log("create interval after", intervalId);
  }

  componentWillUnmount() {
    console.log("componentWillUnmount", intervalId);
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  }


  async getAllSolutionCategories() {
    let language = this.props.languageService.getLanguage();

    let industrySolutionCategories = await this.props.categoryRepository.getExposedCategoriesByType(language, CategoryType.INDUSTRY);
    let applicationSolutionCategories = await this.props.categoryRepository.getExposedCategoriesByType(language, CategoryType.APPLICATION_LOAD);

    this.setState({
        industrySolutionCategories,
        applicationSolutionCategories,
      }
    );
  }

  getCurrentLevelCategories(level: number, parent: number | null): ICategory[] {
    let selectedCategories: ICategory[] = this.props.categoryStore.allCategories;
    if (parent) {
      selectedCategories = selectedCategories.filter((category) => category.parent === parent);
    }
    return selectedCategories.filter((category) => category.level === level);
  }

  async loadAllCategories() {
    await apiutil.start('allCategories');
    if (this.props.categoryStore.allCategories.length === 0) {
      let language = this.props.languageService.getLanguage();
      const allCategories = await this.props.categoryRepository.getExposedCategories(language);
      await this.props.categoryStore.setAllCategories(allCategories);
    }
    apiutil.end('allCategories');
  }

  async isNeedSocialSignupFromRequireLoginPage() {
    const loginType = this.props.history.location.state?.loginType;
    const email = this.props.history.location.state?.email ? this.props.history.location.state?.email : "";

    const isAlreadyMember = await this.props.accountService.isExistAccountId(email);
    if (isAlreadyMember === false &&
      (loginType === LOGIN_TYPE_GOOGLE || loginType === LOGIN_TYPE_KAKAO || loginType === LOGIN_TYPE_NAVER)) {
      return true;
    }

    return false;
  }

  isBrowserIE(): boolean {
    const agent = navigator.userAgent.toLowerCase();

    if (
      (navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') !== -1)
      || (agent.indexOf("MSIE") !== -1)
    ) {
      return true;
    }
    return false;
  }

  async setLoggedInStatus(info: IfLoginInfo) {

    //let showEventPopup = false;
    if (info.userId) await this.checkNewAlarmCount(info.userId);

    // if (showEventPopup) {
    //   this.setState({showUserPopup: false});
    // }
    //
    // this.setState({showEventPopup,});
  }

  async checkNewAlarmCount(loggedInAccountId) {
    axios.post('/api/member/ssqData/user/alarm/count/new', {
      userId: loggedInAccountId
    }).then((res) => {
      this.setState({
        alertAlarmCount: res.data,
        alertNewAlarm: res.data > 0 ? true : false
      });
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({showUserPopup: false});
    }
    if (this.props.isLoginPopup !== prevProps.isLoginPopup && this.props.isLoginPopup) {
      this.props.setShowLoginPopup(true);
    }

    // if (this.props.loginInfo?.userId !== prevState.isLoggedIn ||
    //   this.props.loginInfo?.userIdAdmin !== prevState.isLoggedInAdmin
    // ) {
    //   this.getLoginInfo();
    // }
  }

  trans(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  setLanguage(lang: string) {
    this.props.languageService.setLanguage(lang);
    localStorage.setItem('ssq-language', lang);
    window.location.href = "/";
    //window.location.reload();
  }

  // closeUserPopup() {
  //   this.setState({showUserPopup: false});
  // }

  renderUserMobileCommunityPopup() {
    const sitelang = this.props.languageService.lang();
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <UserPopupWrap>
      <div className="UserPopupContent MobileMenuContent mobile">
        <UserPopup>
          <MobileMenuList className={"Mobile_Menu_list"}>
            <ul>
              <li className={"community-main"}>
                <a href={sitelang == LANG_KO_KR ? `/${country}/${lang}/community/main`
                  : `/${country}/${lang}/community/blog`}>
                  <img alt={"mobile_menu_icon03.svg"} src={SsqImages.header.icon.mobilemenusub03()}/>
                  {this.trans("I18N_TITLE_COMMUNITY")}</a></li>
              {sitelang == LANG_KO_KR &&
                <li className={"submenu"}><a href={`/${country}/${lang}/community/qna`}>{this.trans("I18N_TITLE_COMMUNITY_QNA")}</a>
                </li>}
              <li className={"submenu"}><a href={`/${country}/${lang}/community/blog`}>
                {sitelang == LANG_EN_US ? 'Knowledge Base' : this.trans("I18N_TITLE_COMMUNITY_BLOG")}
              </a></li>
              <li className={"submenu"}><a href={`/${country}/${lang}/community/board`}>{this.trans("I18N_TITLE_COMMUNITY_BOARD")}</a>
              </li>
              <li className={"submenu"}>
                <a href={`/${country}/${lang}/service/sample`}>{this.trans("I18N_TITLE_SERVICE_SAMPLE")}</a>
              </li>
              {roleutil.hasRole('RND', this.props.loginInfo.userRoles) && sitelang == LANG_KO_KR &&
                <li className={"submenu"}>
                  <a href={`/${country}/${lang}/community/rnd`}>{this.trans("I18N_TITLE_COMMUNITY_RND")}</a>
                </li>}
              {/*{(roleutil.hasExposeRole(this.props.loginInfo.userRoles, this.props.languageService.getLanguage())
                || (loginutil.islse(this.props.loginInfo))) && <li className={"submenu"}>
                <a href={`/${country}/${lang}/community/partner`}>{this.trans("I18N_TITLE_COMMUNITY_LS_PARTNER")}</a>
              </li>}*/}
              {((loginutil.iswels(this.props.loginInfo) && [LANG_KO_KR, LANG_EN].includes(sitelang)) || (sitelang == LANG_EN && this.state.isPartner)) &&
                <li className={"submenu"}>
                  <a href={`/${country}/${lang}/community/partner`}>{this.trans("I18N_TITLE_COMMUNITY_LS_PARTNER")}</a>
                </li>}
              <li className={"submenu community-last"}>
              </li>

              <li><a href={`/${country}/${lang}/product/category/0`}>
                <img alt={"mobile_menu_icon01.svg"}
                  src={SsqImages.header.icon.mobilemenusub01()}/>{this.trans("I18N_TITLE_PRODUCT")}
              </a></li>
              {sitelang != LANG_EN_US && <li><a href={`/${country}/${lang}/solution/category/0`}><img alt={"mobile_menu_icon02.svg"}
                src={SsqImages.header.icon.mobilemenusub02()}/>{this.trans("I18N_TITLE_SOLUTIONS")}
              </a></li>}

              <li className={"service-main"}>
                <a href={`/${country}/${lang}/productselector`}>
                  <img alt={"mobile_menu_icon04.svg"} src={SsqImages.header.icon.mobilemenusub04()}/>
                  {this.trans("I18N_TITLE_SERVICE")}</a></li>
              {(loginutil.iswels(this.props.loginInfo)) &&
                <li className={"submenu"}><a href={`/${country}/${lang}/service/assetinsight`}>{this.trans("I81N_TITLE_SERVICE_ASSET_INSIGHT")}</a>
                </li>}
              {this.props.languageService.getLanguage() !== LANG_EN_US &&
                <li className={"submenu"}><a href={`/${country}/${lang}/service/energysave`}>{this.trans("I18N_TITLE_ENERGY_SAVE")}</a>
                </li>}
              <li className={"submenu"}>
                <a href={`/${country}/${lang}/service/classroom`}>{this.trans("I18N_TITLE_SERVICE_CLASSROOM")}</a></li>
              <li className={"submenu"}><a href={`/${country}/${lang}/service/support`}>{this.trans("I18N_TITLE_SERVICE_REMOTE")}</a>
              </li>
              <li className={"submenu"}>
                <a href={`/${country}/${lang}/productselector?view=drive`}>{this.trans("I81N_TITLE_SERVICE_PROD_SELECTOR")}</a></li>
              <li className={"submenu"}>
                <a href={`/${country}/${lang}/productselector?view=hmi`}>{this.trans("I81N_TITLE_SERVICE_HMI_PROD_SELECTOR")}</a></li>
              {this.props.languageService.getLanguage() !== LANG_EN_US && <li className={"submenu"}>
                <a href={`https://www.ls-electric.com/gearbox/${lang}/motor`} target="_blank">
                  {this.trans("I81N_TITLE_SERVICE_GEAR_PROD_SELECTOR")}<img alt={"btn_link_icon.svg"} src={SsqImages.dashboard.btnlink()}/></a>
              </li>}
              {this.props.languageService.getLanguage() !== LANG_EN_US && <li className={"submenu"}>
                <a href={`https://www.ls-electric.com/linear_motor/${lang}/product-search/step1`} target="_blank">
                  {this.trans("I81N_TITLE_SERVICE_LINEAR_PROD_SELECTOR")}<img alt={"btn_link_icon.svg"} src={SsqImages.dashboard.btnlink()}/></a>
              </li>}
            </ul>
          </MobileMenuList>
        </UserPopup>
      </div>
    </UserPopupWrap>;
  }

  allowWritepage(): boolean {
    const lang = this.props.languageService.getLanguage();
    const info = this.props.loginInfo;

    // function
    if (loginutil.islogin(info)) {
      if (loginutil.iswels(info)) return true;
    } else {
      return false;
    }

    if (lang == LANG_KO_KR) return true;
    if (lang == LANG_EN) {
      if (this.state.isPartner) return true;
    }

    return false;
  }

  renderUserPopup() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    const sitelang = this.props.languageService.lang();
    //console.log("renderUserPopup", this.state.isLoadingUserPopup, this.state.showUserPopup);
    return <UserPopupWrap>
      {(!this.state.isLoadingUserPopup && this.state.showUserPopup) &&
        <div className={"UserPopupContent non_mobile"}>
          <UserPopup>
            <UserInfo>
              {loginutil.islogin(this.props.loginInfo) ? this.renderUserInfo() : this.renderLoginMenu()}
            </UserInfo>
            {loginutil.islogin(this.props.loginInfo) && <UserMypage>
              {this.renderUserMypage()}
            </UserMypage>}

            {loginutil.islogin(this.props.loginInfo) && <Userstate>
              {this.renderUserModifyPage()}
            </Userstate>}

            {this.allowWritepage() && <UserPostpage>
              {this.renderPostpage()}
            </UserPostpage>}

            {loginutil.isadmin(this.props.loginInfo) && <UserAdmin>
              <button onClick={() => {
                this.setState({showUserPopup: false});
              }}>
                <Link to={"/borisPage"}>
                  <img alt={"popup_admin.svg"} src={SsqImages.header.icon.boris()}/>
                  {this.trans("I18N_TITLE_SETTINGS")}
                </Link>
              </button>
            </UserAdmin>
            }
            {loginutil.islogin(this.props.loginInfo) && <Userstate>
              {this.renderLogoutMenu()}
            </Userstate>}
          </UserPopup>
        </div>}

      {/*국가언어 버튼 클릭시 팝업*/}
      {this.state.countrySelect &&
        <div className={"UserPopupContent non_mobile"}>
          <UserPopup className="country-select">
            <div>
              <button onClick={() => {
                this.setLanguage(LANG_KO_KR);
                this.setState({countrySelect: false});
              }}>
                <span className="bold">{this.trans("I18N_FILTER_KOREA")}</span>
                <span className="light">{this.trans("I18N_FILTER_KOREAN")}</span>
              </button>
            </div>
            <div>
              <button onClick={() => {
                this.setLanguage(LANG_EN);
                this.setState({countrySelect: false});
              }}>
                <span className="bold">{this.trans("I18N_FILTER_GLOBAL")}</span>
                <span className="light">English</span>
              </button>
            </div>
            <div>
              <button onClick={() => {
                this.setLanguage(LANG_EN_US);
                this.setState({countrySelect: false});
              }}>
                <span className="bold">{this.trans("I18N_FILTER_USA")}</span>
                <span className="light">English</span>
              </button>
            </div>
          </UserPopup>
        </div>}

      {!this.state.isLoadingUserPopup && this.state.showUserPopup &&
        <div className={"UserPopupContent MobileMenuContent mobile"}>
          <UserPopup className={"Mobile_list"}>
            <UserInfo>
              {loginutil.islogin(this.props.loginInfo) ? this.renderUserInfo() : this.renderLoginMenu()}
            </UserInfo>
            {loginutil.islogin(this.props.loginInfo) && <UserMypage>
              {this.renderUserMypage()}
            </UserMypage>}
            <UserLanguage>
              <div className={this.state.langSelect ? "country-select-mobile isopen" : "country-select-mobile"}
                onClick={() => {
                  this.setState({langSelect: this.state.langSelect ? false : true});
                }}>{/*isopen 클래스 추가하면 화살표 방향이 바뀝니다.*/}
                <div>
                  <img alt={""} src={SsqImages.header.icon.globalSelectIconBlue32()} className={"icon"}/>
                  <div className={"global-name"}>
                    <p>{this.trans(`I18N_FILTER_${sitelang.toUpperCase().replace("-", "_")}`)}</p>
                    <p>{this.trans(`I18N_FILTER_LANGUAGE_${sitelang.toUpperCase().replace("-", "_")}`)}</p>
                  </div>
                </div>
                <img src={SsqImages.common.icon.arrowIcon()} alt="" className={"btn"}/>
              </div>
              <div className={`country-select-list ${this.state.langSelect ? 'open' : ''}`}>
                <div className={sitelang === LANG_KO_KR ? "selected" : ""}>
                  <button onClick={() => {
                    this.setLanguage(LANG_KO_KR);
                    this.setState({showUserPopup: false});
                  }}><span>{this.trans("I18N_FILTER_KOREA")}</span><span>{this.trans("I18N_FILTER_KOREAN")}</span>
                  </button>
                </div>
                <div className={sitelang === LANG_EN ? "selected" : ""}>
                  <button onClick={() => {
                    this.setLanguage(LANG_EN);
                    this.setState({showUserPopup: false});
                  }}><span>{this.trans("I18N_FILTER_GLOBAL")}</span><span>English</span>
                  </button>
                </div>
                <div className={sitelang === LANG_EN_US ? "selected" : ""}>
                  <button onClick={() => {
                    this.setLanguage(LANG_EN_US);
                    this.setState({showUserPopup: false});
                  }}><span>{this.trans("I18N_FILTER_USA")}</span><span>English</span>
                  </button>
                </div>
              </div>
            </UserLanguage>

            {loginutil.islogin(this.props.loginInfo) ? <UserPostpage>
              {this.renderPostpage()}
            </UserPostpage> : null}

            {loginutil.islogin(this.props.loginInfo) ?
              <Userstate>
                {this.renderUserModifyPage()}
              </Userstate> : null}

            {loginutil.isadmin(this.props.loginInfo) && <UserAdmin>
              <button onClick={() => {
                this.setState({showUserPopup: false});
              }}>
                <Link to={"/borisPage"}>
                  <img alt={"popup_admin.svg"} src={SsqImages.header.icon.boris()}/>
                  {this.trans("I18N_TITLE_SETTINGS")}
                </Link>
              </button>
            </UserAdmin>
            }
            {loginutil.islogin(this.props.loginInfo) && <Userstate>
              {this.renderLogoutMenu()}
            </Userstate>}
          </UserPopup>
        </div>}
    </UserPopupWrap>;
  }

  renderLoginMenu() {
    return <button className={"login_btn"} onClick={() => {
      this.props.setShowLoginPopup(true);
      this.setState({showUserPopup: false});
    }}><img alt={"popup_login.svg"} src={SsqImages.header.icon.login()}/>
      {this.trans("I18N_TITLE_LOGIN_HEADER")}
    </button>;
  }

  getTechPointCls(techPoint: number) {
    if (techPoint >= 1000) return 'first';
    else if (techPoint >= 500) return 'second';
    else if (techPoint >= 100) return 'third';
    else if (techPoint >= 30) return 'fourth';
    return 'fifth';
  }

  renderUserInfo() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    const techPoint = this.props.loginInfo?.techPoint;
    return <div className={"user_info_div"}>
      <Link to={{pathname: `/${country}/${lang}/myPage`, state: {id: this.props.loginInfo?.userId}}}>
        <div className={"user_info"}>
          <UserProfileImage headerStyle={'userInfo'} loginInfo={this.props.loginInfo}/>
          <div>
            <p className={"name"}>{this.props.loginInfo?.nickname}
              <span className={`${this.getTechPointCls(techPoint)}`}>{techPoint}</span>
            </p>
            {/*<p className={"name_alt"}>{this.state.loggedInNickname}</p>*/}
            <p className={"my-alarm-txt"}>{this.trans('I18N_TITLE_ALARMS')} <span
              className="alarm-counter">+{this.state.alertAlarmCount}</span></p>
          </div>

          {/*{gconf.showPublic ? this.renderUserLevel() : <p className={"user_level"}>{this.getTranslation("I18N_TITLE_LEVEL")}</p>}
        관리자는 삭제 요청*/}

        </div>
      </Link>
    </div>;
  }

  renderUserLevel() {
    if (loginutil.isadmin(this.props.loginInfo)) {
      return <p className={"user_level"}>{this.trans("I18N_TITLE_LEVEL_ADMIN")}</p>;
    }
    return <p className={"user_level"}>{this.trans("I18N_TITLE_LEVEL")}</p>;
  }

  renderUserMypage() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <button>
      <Link to={{pathname: `/${country}/${lang}/myPage`, state: {id: this.props.loginInfo?.userId}}}
        onClick={() => {
          (window.location.pathname === `/${country}/${lang}/myPage`) && window.location.reload();
          this.setState({showUserPopup: false});
        }}>
        <div className="alarm-active">
          <img alt={"popup_mypage.svg"} src={SsqImages.header.icon.loggedInUser()}/>
          {/*{this.state.alertNewAlarm ? <span className={"circle"}/> : null}*/}
          {this.trans("I18N_TITLE_MYPAGE")}
        </div>
      </Link>
    </button>;
  }

  renderUserModifyPage() {
    return <button onClick={() => {
      this.setState({showManageMyInfoPopup: true});
    }}>
      <div>
        <img alt={"user_settings.svg"} src={SsqImages.header.icon.userSettings()}/>
        {this.trans("I18N_TITLE_USER_SETTINGS")}
      </div>
    </button>;
  }

  renderPostpage() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());

    return <button style={{padding: "1.6rem"}} onClick={() => window.open(`/${country}/${lang}/community/new/0`, "_blank")}>
      <img alt={"popup_post.svg"} src={SsqImages.header.icon.post()}/>
      {this.trans("I18N_TITLE_POST")}
    </button>;
  }

  renderLogoutMenu() {
    return <button onClick={async () => {
      if (loginutil.islse(this.props.loginInfo)) {
        await this.props.loginService.logoutAdmin();
      } else {
        await this.props.loginService.logout();
      }
      window.localStorage.removeItem("RECENT_EXCEPT_LECTURE_VALUE");
      this.setState({
        showUserPopup: false,
        //isLoggedIn: false,
        loginInputValues: {id: "", password: ""}
      });
      this.props.setLoginInfo({} as IfLoginInfo);

      window.location.replace("/");
    }}><img alt={"popup_logout.svg"} src={SsqImages.header.icon.logout()}/>
      {this.trans("I18N_TITLE_LOGOUT")}
    </button>;
  }

  selectMenu(key: string, urlString: string) {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());

    return <div className={this.props.history.location.pathname.includes(urlString) ?
      this.props.history.location.pathname.includes(`/${country}/${lang}/service/sample`) ? "" : "selected"
      : this.props.history.location.pathname.includes(`/${country}/${lang}/service/sample`) && urlString == `/${country}/${lang}/community` ? "selected" : ""}>
      {this.trans(key)}
    </div>;
  }

  selectedHeader() {
    const headerList = ['/product/', '/solution/', '/community/', '/service/']; // 해더 목록
    const url = this.props.history.location.pathname;
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());

    if (url.includes(`/${country}/${lang}/service/sample`)) return this.setState({selectedHeader: '/community/'});
    if (url.includes('productselector')) return this.setState({selectedHeader: '/service/'});

    /*산업페이지에서 목록 클릭*/
    if ((document.referrer.includes('/solution/category/0') && url.includes("/community/blog/document/"))) {
      return this.setState({selectedHeader: '/solution/'});
    }

    /*산업페이지 헤더에서 커뮤니티 헤더 클릭*/
    if (url.includes("/community/")) {
      return this.setState({selectedHeader: '/community/'});
    }

    const selectedHeader = headerList.find(element => url.includes(element)) || '';
    return this.setState({selectedHeader});
  }

  showLoginPopupFunc = (showLoginPopup: boolean) => {
    //this.setState({showLoginPopup: showLoginPopup});
    this.props.setShowLoginPopup(showLoginPopup);
  };

  // setIsLoggedInFunc = (isLoggedIn: boolean) => {
  //   this.setState({isLoggedIn});
  // };

  setLoginInputValuesFunc = (loginInputValues: LoginInputValues) => {
    this.setState({loginInputValues: loginInputValues});
  };

  showLoginFailPopupFunc = (showLoginFailPopup: boolean, loginInputValues?: LoginInputValues) => {
    this.props.setShowLoginPopup(true);
    this.setState({showLoginFailPopup});

    if (loginInputValues) {
      this.setState({loginInputValues});
    }
  };

  showSignupPopupFunc = (showSignupPopup: boolean) => {
    this.setState({showSignupPopup: showSignupPopup});
  };

  closeSocialSignupPopupFunc = () => {
    this.setState({showSignupSocialPopup: false});
    this.props.setShowLoginPopup(true);
  };

  closeAllPopupFunc = (showUserPopup: boolean,
    showLoginPopup: boolean,
    showSignupPopup: boolean,
    showSendVerifyEmailPopup: boolean,
    showPasswordResetPopup: boolean,
    showSendPasswordResetEmailPopup: boolean) => {
    this.props.setShowLoginPopup(showLoginPopup);
    this.setState({
      showUserPopup: showUserPopup,
      showSignupPopup: showSignupPopup,
      showSendVerifyEmailPopup: showSendVerifyEmailPopup,
      showPasswordResetPopup: showPasswordResetPopup,
      showSendPasswordResetEmailPopup: showSendPasswordResetEmailPopup,
      loginInputValues: {id: "", password: ""}
    });
  };

  async openUserPopup(showUserPopup) {
    if (!showUserPopup) {
      await this.setState({showUserPopup: !showUserPopup, isLoadingUserPopup: true, countrySelect: false, showMobileCommunityMenuPopup: false});

      const info: IfLoginInfo = (await axios.get('/api/guest/loginInfo')).data;
      await this.setLoggedInStatus(info);

      this.setState({isLoadingUserPopup: false});
    } else {
      this.setState({showUserPopup: !showUserPopup, isLoadingUserPopup: false, countrySelect: false, showMobileCommunityMenuPopup: false});
    }
  }

  openUserMobileCommunityPopup(showMobileCommunityMenuPopup: boolean) {
    this.setState({showMobileCommunityMenuPopup: !showMobileCommunityMenuPopup, countrySelect: false, showUserPopup: false});
  }

  openCountryPopup(countrySelect: boolean) {
    this.setState({showUserPopup: false, countrySelect: !countrySelect});
  }

  showTermsDetailPopupFunc = (showTermsDetailPopup: boolean) => {
    this.setState({showTermsDetailPopup: showTermsDetailPopup});
  };

  showTermsDetailPrivatePopupFunc = (showTermsDetailPrivatePopup: boolean, termsType: string) => {
    this.setState({
      showTermsDetailPrivatePopup,
      termsType
    });
  };

  showOriginalVersionTermsDetailPopupFunc = (showOriginalVersionTermsDetailPopup: boolean) => {
    this.setState({showOriginalVersionTermsDetailPopup});
  };

  showSendVerifyEmailPopupFunc = (showSendVerifyEmailPopup: boolean) => {
    this.setState({showSendVerifyEmailPopup: showSendVerifyEmailPopup});
  };

  showPasswordResetPopupFunc = (showPasswordResetPopup: boolean) => {
    this.setState({showPasswordResetPopup: showPasswordResetPopup});
  };

  showSendPasswordResetEmailPopupFunc = (showSendPasswordResetEmailPopup: boolean) => {
    this.setState({showSendPasswordResetEmailPopup: showSendPasswordResetEmailPopup});
  };

  setSignupEmailFunc = (signupEmail: string) => {
    this.setState({signupEmail: signupEmail});
  };

  setResetPasswordEmailFunc = (resetPasswordEmail: string) => {
    this.setState({resetPasswordEmail: resetPasswordEmail});
  };

  showSignupSocialPopupFunc = (
    showSignupSocialPopup: boolean,
    socialSignupInfo: SocialSignupInfo) => {

    this.setState({
      showSignupSocialPopup: showSignupSocialPopup,
      socialSignupInfo: socialSignupInfo
    });
  };

  showSocialSignupPopupFunc = () => {
    const signupInfo: SocialSignupInfo = {
      email: this.props.history.location.state.email,
      socialId: this.props.history.location.state.socialId,
      loginType: this.props.history.location.state.loginType,
    };

    this.setState({
      showSignupSocialPopup: true,
      socialSignupInfo: signupInfo
    });
  };


  renderProductMenuList() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    let displayIndexList = [];
    let lastIdx = this.state.productCategories.length;
    for (let i = 0; i <= lastIdx; i++) {
      displayIndexList.push(i);
    }
    return <div className={"sub-menu-cont"}> {displayIndexList.map((categoryIndex: number, index: number) => {
      const category = this.state.productCategories[categoryIndex];
      {/*아래의 div에 sold-out 추가하시면 됩니다.*/
      }
      return category && <div key={`main-${category.id}`}>
        <a href={`/${country}/${lang}/product/category/${category.id}`} className={clsx(category.discontinue && 'sold-out')}>
          <div className={"category-img"}>
            <img alt={`category-${category.id}.png`}
              src={ssqutil.getCdnUrl(category.imageBlobKey)}
              onError={ssqutil.imgOnErrorNoImage}
            />
          </div>
          <div className={"category-name"} key={index}>{category.name}
            {category.cardDescription.length > 0 && <span>{category.cardDescription}</span>}
          </div>
        </a>
      </div>;
    })}
    </div>;
  }

  renderIndusyryMenuList() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    let displayIndexList = [];
    for (let i = 0; i <= 7; i++) {
      displayIndexList.push(i);
    }
    return <div>
      <p className={"type-tit"}>{this.trans("I18N_TITLE_SOLUTIONS_TYPE01")}</p>
      <div className={"sub-menu-cont"}>
        {displayIndexList.map((categoryIndex: number, index: number) => {
          const Industrycategory = this.state.industrySolutionCategories[categoryIndex];
          return Industrycategory && <div key={`main-${Industrycategory.id}`}>
            <a href={`/${country}/${lang}/solution/category/${Industrycategory.id}`}>
              <div className={"category-img"}>
                <img alt={`category-${Industrycategory.id}.png`}
                  src={ssqutil.getCdnUrl(Industrycategory.imageBlobKey)}
                  onError={ssqutil.imgOnErrorNoImage}
                />
              </div>
              <div className={"category-name"}
                key={index}>{Industrycategory.name}
                {Industrycategory.cardDescription.length > 0 &&
                  <span>{Industrycategory.cardDescription}</span>}
              </div>
            </a>
          </div>;
        })}
      </div>
    </div>;
  }

  renderApplicationMenuList() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    let displayIndexList = [];
    for (let i = 0; i <= 7; i++) {
      displayIndexList.push(i);
    }
    return <div>
      <p className={"type-tit"}>{this.trans("I18N_TITLE_SOLUTIONS_TYPE02")}</p>
      <div className={"sub-menu-cont"}> {displayIndexList.map((categoryIndex: number, index: number) => {
        const Applicationcategory = this.state.applicationSolutionCategories[categoryIndex];
        return Applicationcategory && <div key={`main-${Applicationcategory.id}`}>
          <a href={`/${country}/${lang}/solution/category/${Applicationcategory.id}`}>
            <div className={"category-img"}>
              <img alt={`category-${Applicationcategory.id}.png`}
                src={ssqutil.getCdnUrl(Applicationcategory.imageBlobKey)}
                onError={ssqutil.imgOnErrorNoImage}
              />
            </div>
            <div className={"category-name"}
              key={index}>{Applicationcategory.name}
              {Applicationcategory.cardDescription.length > 0 &&
                <span>{Applicationcategory.cardDescription}</span>}
            </div>
          </a>
        </div>;
      })}
      </div>
    </div>;
  }

  // getProfileImg(imageFile: string) {
  //   return imageFile == null || imageFile == "" ? SsqImages.mypage.avatar["avatar06"]() :
  //     imageFile.startsWith("avatar") ? SsqImages.mypage.avatar[imageFile]() :
  //       imageFile.startsWith("myphoto") ? `/uploads/${this.props.loginInfo?.profilePhotoFileKey}/avatar.svg` : null;
  // }


  renderCommunityMenuList() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <div className="sub-menu-cont-2">
      {this.props.languageService.lang() === LANG_KO_KR && this.renderCategorySubList(`/${country}/${lang}/community/qna`, "qna.svg", "I18N_TITLE_COMMUNITY_QNA", "I18N_TITLE_COMMUNITY_QNA_INTRO")}
      {this.renderCategorySubList(`/${country}/${lang}/community/blog`, "blog.svg", "I18N_TITLE_COMMUNITY_BLOG", "I18N_TITLE_COMMUNITY_BLOG_INTRO")}
      {this.renderCategorySubList(`/${country}/${lang}/community/board`, "board.svg", "I18N_TITLE_COMMUNITY_BOARD", "I18N_TITLE_COMMUNITY_BOARD_INTRO")}
      {this.renderCategorySubList(`/${country}/${lang}/service/sample`, "sample.svg", "I18N_TITLE_SERVICE_SAMPLE", "I18N_TITLE_SERVICE_SAMPLE_INTRO")}
      {/*{(roleutil.hasExposeRole(this.props.loginInfo.userRoles, this.props.languageService.getLanguage()) || (loginutil.islse(this.props.loginInfo)))
        && this.renderCategorySubList(`/${country}/${lang}/community/partner`,
          "partner.svg", "I18N_TITLE_COMMUNITY_LS_PARTNER", "I18N_TITLE_COMMUNITY_LS_PARTNER_INTRO")}*/}
      {((loginutil.iswels(this.props.loginInfo)) && [LANG_EN].includes(this.props.languageService.getLanguage())
        || (this.props.languageService.lang() == LANG_EN && this.state.isPartner)
      ) && this.renderCategorySubList(`/${country}/${lang}/community/partner`,
        "partner.svg", "I18N_TITLE_COMMUNITY_LS_PARTNER", "I18N_TITLE_COMMUNITY_LS_PARTNER_INTRO")}
      {this.props.languageService.getLanguage() == LANG_KO_KR && roleutil.hasRole('RND', this.props.loginInfo.userRoles) && this.renderCategorySubList(`/${country}/${lang}/community/rnd`, "rnd.svg", "I18N_TITLE_COMMUNITY_RND", "I18N_TITLE_COMMUNITY_RND_INTRO")}
    </div>;
  }

  renderServiceMenuList() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <>
      <div>
        <p className={"type-tit"}>{this.trans("I18N_TITLE_SERVICE_TYPE01")}</p>
        <div className="sub-menu-cont-2">
          {(loginutil.iswels(this.props.loginInfo)) &&
            this.renderCategorySubList(`/${country}/${lang}/service/assetinsight`, "asset.svg", "I81N_TITLE_SERVICE_ASSET_INSIGHT", "I18N_TITLE_ASSET_INTRO")}
          {this.props.languageService.getLanguage() !== LANG_EN_US &&
            this.renderCategorySubList(`/${country}/${lang}/service/energysave`, "simul.svg", "I18N_TITLE_ENERGY_SAVE", "I18N_TITLE_ENERGY_SAVE_INTRO")}
          {this.props.languageService.getLanguage() == LANG_EN_US ?
            this.renderCategorySubList('https://www.lselectricamerica.com/training/', 'classroom.svg', "Online Training", "Join training courses")
            : this.renderCategorySubList(`/${country}/${lang}/service/classroom`, "classroom.svg", "I18N_TITLE_SERVICE_CLASSROOM", "I18N_TITLE_SERVICE_CLASSROOM_INTRO")}
          {this.renderCategorySubList(`/${country}/${lang}/service/support`, "remote.svg", "I18N_TITLE_SERVICE_REMOTE", "I18N_TITLE_SERVICE_REMOTE_INTRO")}

        </div>
      </div>
      <div>
        <p className={"type-tit"}>{this.trans("I18N_TITLE_SERVICE_TYPE02")}</p>
        <div className="sub-menu-cont-2">
          {this.renderCategorySubList(`/${country}/${lang}/productselector?view=drive`, "icon_side_drive.svg", "I81N_TITLE_SERVICE_PROD_SELECTOR", "I81N_TITLE_SERVICE_PROD_SELECTOR_INTRO")}
          {this.renderCategorySubList(`/${country}/${lang}/productselector?view=hmi`, 'icon_side_hmi.svg', "I81N_TITLE_SERVICE_HMI_PROD_SELECTOR", "I81N_TITLE_SERVICE_PROD_SELECTOR_INTRO")}
          {this.props.languageService.getLanguage() !== LANG_EN_US &&
            this.renderCategorySubList(`https://www.ls-electric.com/gearbox/${lang}/motor`, 'icon_side_deboost.svg', "I81N_TITLE_SERVICE_GEAR_PROD_SELECTOR", "I81N_TITLE_SERVICE_GEAR_PROD_SELECTOR_INTRO")}
          {this.props.languageService.getLanguage() !== LANG_EN_US &&
            this.renderCategorySubList(`https://www.ls-electric.com/linear_motor/${lang}/product-search/step1`, 'icon_side_motor.svg', "I81N_TITLE_SERVICE_LINEAR_PROD_SELECTOR", "I81N_TITLE_SERVICE_LINEAR_PROD_SELECTOR_INTRO")}
        </div>
      </div>
    </>;
  }

  renderCategorySubList(link: string, imageUrl: string, title: string, intro: string) {
    const renderMark = () => {
      if (title === "I18N_TITLE_COMMUNITY_RND") return <div className={"rnd-mark"}>{this.trans("I18N_TITLE_COMMUNITY_RND_MARK")}</div>;
    };

    const partnerMark = () => {
      if (title === 'I18N_TITLE_COMMUNITY_LS_PARTNER') return <div className={"partner-mark"}>{this.trans("I18N_TITLE_COMMUNITY_PARTNER_MARK")}</div>;
    };

    return <a href={`${link}`} target={link.startsWith("https://") ? "_blank" : "_self"}>
      <div
        className={clsx("category-img", title == 'I81N_TITLE_SERVICE_PROD_SELECTOR' || title == 'I81N_TITLE_SERVICE_HMI_PROD_SELECTOR' || title == 'I81N_TITLE_SERVICE_GEAR_PROD_SELECTOR' || title == 'I81N_TITLE_SERVICE_LINEAR_PROD_SELECTOR' ? "product-selector" : "community-service")}>
        <img alt={imageUrl} src={SsqImages.header.icon.category(imageUrl)} onError={ssqutil.imgOnErrorNoImage}/>
      </div>
      <div className="category-name">
        <div className="container">
          <div className="title">{this.trans(title)}</div>
          {renderMark()}
          {partnerMark()}
          {link.startsWith("https://") && <img alt={"btn_link_icon.svg"} src={SsqImages.dashboard.btnlink()}/>}
        </div>
        <span>{this.trans(intro)}</span></div>
    </a>;
  }

  // showEventPopupFunc = (showEventPopup: boolean) => {
  //   this.setState({showEventPopup: showEventPopup});
  // };

  // renderEventPopup() {
  //   return <IdeaEventPopup
  //     showEventPopupFunc={this.showEventPopupFunc}
  //     localStorageWrapper={this.props.localStorageWrapper}
  //     languageService={this.props.languageService}
  //   />;
  // }

  gearBoxLink() {
    const accessCountry = this.props.languageService.getLanguage();
    if (accessCountry == LANG_KO_KR) return 'https://www.ls-electric.com/gearbox/ko/motor'; // korean
    return 'https://www.ls-electric.com/gearbox/en/motor'; // global, us
  }

  linearMotorLink() {
    const accessCountry = this.props.languageService.getLanguage();
    if (accessCountry == LANG_KO_KR) return 'https://www.ls-electric.com/linear_motor/ko/product-search/step1'; // korean
    return 'https://www.ls-electric.com/linear_motor/en/product-search/step1'; // global, us
  }

  render() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    //console.log("============> ", this.props.isExistSsqAccountNotSnsStore);
    const headerMenuClick = (e, target) => {
      const ptype = (e.nativeEvent as any)?.pointerType;
      if (ptype === "touch" || ptype === "pen") {
        e.preventDefault();
        if (target === 'community') this.setState({CommunityMenu: true});
        else if (target === 'product') this.setState({ProductMenu: true});
        else if (target === 'solution') this.setState({SolutionMenu: true});
        else if (target === 'service') this.setState({ServiceMenu: true});
      }
    };
    const sitelang = this.props.languageService.lang();

    return <>
      <HeaderContainer showSearchBoxPopup={this.props.showSearchBoxPopup} style={{display: this.props.hideHeader && 'none'}}>
        {/*{this.state.showEventPopup &&*/}
        {/*  <AllPopupWrap className={"allpopupwrap"}>*/}
        {/*    <React.Suspense fallback={<div></div>}>*/}
        {/*      {new Date(this.EXPIRED_EVENT_TIME) > new Date() && this.renderEventPopup()}*/}
        {/*    </React.Suspense>*/}
        {/*  </AllPopupWrap>*/}
        {/*}*/}
        <HeaderContents className={"container"}>
          <div className="logo-area">
            <a href={`/${country}/${lang}/main`}>
              {/*<img alt={"ssq_logo.svg"} src={SsqImages.common.logo.ssq()}/>*/}
              <img alt={"ssq_logo.svg"} src={SsqImages.common.logo.lsHeader()}/>
              <span className="solution-square">Solution Square</span>
            </a>
          </div>
          <div className="menu-area">
            <div onMouseEnter={() => this.setState({CommunityMenu: true})}
              onMouseLeave={() => this.setState({CommunityMenu: false})}>
              <a href={sitelang == LANG_KO_KR ? `/${country}/${lang}/community/main` : `/${country}/${lang}/community/blog`}
                onClick={(e) => headerMenuClick(e, 'community')}>
                <div className={this.state.selectedHeader == '/community/' ? 'selected' : ''}>{this.trans('I18N_TITLE_COMMUNITY')}</div>
              </a>
              <SubMenu className={(this.state.CommunityMenu === true ? "on" : "")} tDiscontinue={i18ntrans.admin('I18N_SUBJECT_DISCONTINUE')}>
                <div className={"container"}>
                  <p className={"sub-menu-tit"}>{this.trans("I18N_TITLE_COMMUNITY")}</p>
                  <div className={"sub-menu-list"}>
                    <div className={"sub-menu-cont-2s"}>
                    </div>
                    {this.renderCommunityMenuList()}
                  </div>
                </div>
              </SubMenu>
            </div>

            <div onMouseEnter={() => this.setState({ProductMenu: true})}
              onMouseLeave={() => this.setState({ProductMenu: false})}>
              <a href={`/${country}/${lang}/product/category/0`} onClick={(e) => headerMenuClick(e, 'product')}>
                <div className={this.state.selectedHeader == '/product/' ? 'selected' : ''}>{this.trans('I18N_TITLE_PRODUCT')}</div>
              </a>
              <SubMenu className={clsx("product-guide-menu", this.state.ProductMenu === true ? "on" : "")}
                tDiscontinue={i18ntrans.admin('I18N_SUBJECT_DISCONTINUE')}>
                <div className={"container"}>
                  <p className={"sub-menu-tit"}>{this.trans("I18N_TITLE_PRODUCT")}</p>
                  <div className={"sub-menu-list"}>
                    {this.renderProductMenuList()}
                  </div>
                </div>

                {/* 제품 선정 가이드 메뉴 */}
                <div className="product-guide">
                  <div className={"pg-container"}>
                    <p className={"sub-menu-tit"}>{this.trans('I18N_PRODUCT_SELECTOR')}</p>
                    <div className={"sub-menu-list"}>
                      <div className={"sub-menu-cont"}>
                        <div>
                          <a href={`/${country}/${lang}/productselector?view=drive`}>
                            <div className="category-img product-selector">
                              <img src={SsqImages.product.SideGuideDrive()} alt=""/>
                            </div>
                            <div
                              className={"category-name"}>Drive {this.props.languageService.getLanguage() == LANG_KO_KR && this.trans('I81N_TITLE_SELECTOR')}</div>
                          </a>
                        </div>
                        <div>
                          <a href={`/${country}/${lang}/productselector?view=hmi`}>
                            <div className="category-img product-selector">
                              <img src={SsqImages.product.SideGuideHmi()} alt=""/>
                            </div>
                            <div
                              className={"category-name"}>HMI {this.props.languageService.getLanguage() == LANG_KO_KR && this.trans('I81N_TITLE_SELECTOR')}</div>
                          </a>
                        </div>
                        {this.props.languageService.getLanguage() !== LANG_EN_US && <div>
                          <a href={this.gearBoxLink()} target={'_blank'}>
                            <div className="category-img product-selector">
                              <img src={SsqImages.product.SideGuideDeboost()} alt=""/>
                            </div>
                            <div
                              className={"category-name"}>{this.trans('I18N_PRODUCT_SELECTOR_GEARBOX')} {this.props.languageService.getLanguage() == LANG_KO_KR && this.trans('I81N_TITLE_SELECTOR')}
                              <button className="link"><img src={SsqImages.product.SideGuideLink()} alt=""/></button>
                            </div>
                          </a>
                        </div>}
                        {this.props.languageService.getLanguage() !== LANG_EN_US && <div>
                          <a href={this.linearMotorLink()} target={'_blank'}>
                            <div className="category-img product-selector">
                              <img src={SsqImages.product.SideGuideMotor()} alt=""/>
                            </div>
                            <div
                              className={"category-name"}>{this.trans('I18N_PRODUCT_SELECTOR_LINEAR_MOTOR')} {this.props.languageService.getLanguage() == LANG_KO_KR && this.trans('I81N_TITLE_SELECTOR')}
                              <button className="link"><img src={SsqImages.product.SideGuideLink()} alt=""/></button>
                            </div>
                          </a>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>

              </SubMenu>
            </div>
            {this.props.languageService.getLanguage() !== LANG_EN_US &&
              <div onMouseEnter={() => this.setState({SolutionMenu: true})}
                onMouseLeave={() => this.setState({SolutionMenu: false})}>
                <a href={`/${country}/${lang}/solution/category/0`} onClick={(e) => headerMenuClick(e, 'solution')}>
                  <div className={this.state.selectedHeader == '/solution/' ? 'selected' : ''}>{this.trans('I18N_TITLE_SOLUTIONS')}</div>
                </a>
                <SubMenu className={(this.state.SolutionMenu === true ? "on" : "")} tDiscontinue={i18ntrans.admin('I18N_SUBJECT_DISCONTINUE')}>
                  <div className={"container"}>
                    <p className={"sub-menu-tit"}>{this.trans("I18N_TITLE_SOLUTIONS")}</p>
                    <div className={"half-menu sub-menu-list"}>
                      {this.renderIndusyryMenuList()}
                      {this.renderApplicationMenuList()}
                    </div>
                  </div>
                </SubMenu>
              </div>}

            <div onMouseEnter={() => this.setState({ServiceMenu: true})}
              onMouseLeave={() => this.setState({ServiceMenu: false})}>
              <a
                href={`/${country}/${lang}/productselector`}
                onClick={(e) => headerMenuClick(e, 'service')}>
                <div className={this.state.selectedHeader == '/service/' ? 'selected' : ''}>{this.trans('I18N_TITLE_SERVICE')}</div>
              </a>
              <SubMenu className={(this.state.ServiceMenu === true ? "on" : "")} tDiscontinue={i18ntrans.admin('I18N_SUBJECT_DISCONTINUE')}>
                <div className={"container"}>
                  <p className={"sub-menu-tit"}>{this.trans("I18N_TITLE_SERVICE")}</p>
                  <div className={"half-menu sub-menu-list"}>
                    {this.renderServiceMenuList()}
                  </div>
                </div>
              </SubMenu>
            </div>
          </div>
          <div className="icon-area"> {/*헤더 오른쪽 아이콘 영역*/}
            <button className="search-box-btn"
              onClick={() => {
                !this.props.location.pathname.includes("allSearch") &&
                this.props.onClickSearchButton();
                this.setState({countrySelect: false});
                this.setState({showUserPopup: false});
              }}><span>{this.trans("I18N_TITLE_SEARCH")}</span>
              <img alt={"search_icon.svg"} src={SsqImages.header.icon.search()}/>
            </button>
            <button className="global-select-header" onClick={() => {
              this.props.closeSearchPopup();
              this.openCountryPopup(this.state.countrySelect);
            }}>
              <span>{this.trans(`I18N_FILTER_${sitelang.toUpperCase().replace("-", "_")}`)}</span>
            </button>
            <div className="my-icon-area">
              <UserProfileBtn onClick={async () => {
                this.props.closeSearchPopup();
                if (loginutil.islogin(this.props.loginInfo)) await this.checkNewAlarmCount(this.props.loginInfo?.userId);
                await this.openUserPopup(this.state.showUserPopup);
              }}
                loginInfo={this.props.loginInfo}
                isLoadingUserPopup={this.state.isLoadingUserPopup}
                alertNewAlarm={this.state.alertNewAlarm}
                isMobile={false}
              />
            </div>
          </div>
          <MobileMenu>
            <UserProfileBtn onClick={async () => {
              this.props.closeSearchPopup();
              if (loginutil.islogin(this.props.loginInfo)) await this.checkNewAlarmCount(this.props.loginInfo?.userId);
              await this.openUserPopup(this.state.showUserPopup);
            }}
              loginInfo={this.props.loginInfo}
              isLoadingUserPopup={this.state.isLoadingUserPopup}
              alertNewAlarm={this.state.alertNewAlarm}
              isMobile={true}
            />
            <button className={"header_search_icon"}
              onClick={() => {
                !this.props.location.pathname.includes("allSearch") &&
                this.props.onClickSearchButton();
                this.setState({showUserPopup: false, showMobileCommunityMenuPopup: false});
              }}>
              <img alt={"search_icon.svg"} src={SsqImages.header.icon.search()}/>
            </button>
            <button onClick={async () => {
              await this.openUserMobileCommunityPopup(this.state.showMobileCommunityMenuPopup);
            }}>
              <img alt={"mobile_menu.svg"} src={SsqImages.header.icon.mobilemenu()}/>
            </button>
          </MobileMenu>
        </HeaderContents>
      </HeaderContainer>
      <LoadingLayer visible={this.props.loading}/>
      <React.Suspense fallback={<div></div>}>
        {this.renderUserPopup()}
      </React.Suspense>
      {this.state.showMobileCommunityMenuPopup && this.renderUserMobileCommunityPopup()}
      <AllPopupWrap>

        <React.Suspense fallback={<div></div>}>
          {this.props.showLoginPopup && this.renderLoginPopupOrLoginFailPopup()}
          {this.state.showSignupPopup && this.renderSignupPopup()}
          {this.state.showSignupSocialPopup && this.renderSignupSocialPopup()}
          {this.state.showTermsDetailPopup && this.renderTermsDetailPopup()}
          {this.state.showTermsDetailPrivatePopup && this.renderTermsDetailPrivatePopup()}
          {this.state.showSendVerifyEmailPopup && this.renderSendVerifyEmailPopup()}
          {this.state.showPasswordResetPopup && this.renderPasswordResetPopup()}
          {this.state.showSendPasswordResetEmailPopup && this.renderSendPasswordResetEmailPopup()}
          {this.state.showManageMyInfoPopup && this.renderManageMyInfoPopup()}
        </React.Suspense>
      </AllPopupWrap>
      {(this.state.showUserPopup || this.state.countrySelect) && <TranPopupOverlay
        aria-label={"popup-overlay"}
        onClick={() => {
          this.setState({showUserPopup: false, countrySelect: false, showMobileCommunityMenuPopup: false});
        }}
      />}
      {(this.state.showMobileCommunityMenuPopup || this.state.countrySelect) && <TranPopupOverlay
        aria-label={"popup-overlay"}
        onClick={() => {
          this.setState({showUserPopup: false, countrySelect: false, showMobileCommunityMenuPopup: false});
        }}
      />}
      {(this.props.showLoginPopup || this.state.showSignupPopup || this.state.showSignupSocialPopup || this.state.showManageMyInfoPopup
          || this.state.showTermsDetailPopup)
        && <AllPopupOverlay aria-label={"popup-overlay"}/>}
    </>;
  }

  closeManageMyInfoPopup = () => {
    this.setState({showManageMyInfoPopup: false});
  };

  private renderManageMyInfoPopup() {
    return <ManageMyInfoPopup
      closeEvent={this.closeManageMyInfoPopup}
      accountService={this.props.accountService}
      loginService={this.props.loginService}
    />;
  }

  private renderLoginPopupOrLoginFailPopup() {
    return <>
      {this.state.showLoginFailPopup &&
        <LoginFailPopup
          languageService={this.props.languageService}
          accountService={this.props.accountService}
          showLoginFailPopupFunc={this.showLoginFailPopupFunc}
          loginFailedAccountId={this.state.loginInputValues.id}
        />
        ||
        <LoginPopup
          languageService={this.props.languageService}
          loginService={this.props.loginService}
          accountService={this.props.accountService}
          loginInputValues={this.state.loginInputValues}
          showLoginPopupFunc={this.showLoginPopupFunc}
          showLoginFailPopupFunc={this.showLoginFailPopupFunc}
          setIsLoggedInFunc={(b) => this.getLoginInfoNow()}
          setLoginInputValuesFunc={this.setLoginInputValuesFunc}
          showSignupPopupFunc={this.showSignupPopupFunc}
          showSendVerifyEmailPopupFunc={this.showSendVerifyEmailPopupFunc}
          setSignupEmailFunc={this.setSignupEmailFunc}
          showSignupSocialPopupFunc={this.showSignupSocialPopupFunc}
          showPasswordResetPopupFunc={this.showPasswordResetPopupFunc}
        />}
    </>;
  }

  private renderSignupPopup() {
    return <SignupPopup
      languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      showSignupPopupFunc={this.showSignupPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
      showTermsDetailPrivatePopupFunc={this.showTermsDetailPrivatePopupFunc}
      setSignupEmailFunc={this.setSignupEmailFunc}
      showSendVerifyEmailPopupFunc={this.showSendVerifyEmailPopupFunc}
    />;
  }

  private renderSignupSocialPopup() {
    return <SignupSocialPopup
      languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
      showTermsDetailPrivatePopupFunc={this.showTermsDetailPrivatePopupFunc}
      showSignupSocialPopupFunc={this.showSignupSocialPopupFunc}
      showLoginPopupFunc={this.showLoginPopupFunc}
      socialSignupInfo={this.state.socialSignupInfo}
      closeSocialSignupPopupFunc={this.closeSocialSignupPopupFunc}
    />;
  }

  private renderTermsDetailPopup() {
    return <TermsDetailPopup
      languageService={this.props.languageService}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
    />;
  }

  private renderTermsDetailPrivatePopup() {
    return <TermsDetailPrivatePopup
      languageService={this.props.languageService}
      showTermsDetailPrivateEssentialPopupFunc={this.showTermsDetailPrivatePopupFunc}
      termsType={this.state.termsType}
    />;
  }

  private renderSendVerifyEmailPopup() {
    return <SendVerifyEmailPopup
      languageService={this.props.languageService}
      notificationService={this.props.notificationService}
      signupEmail={this.state.signupEmail}
      showSendVerifyEmailPopupFunc={this.showSendVerifyEmailPopupFunc}
      showSignupPopupFunc={this.showSignupPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
    />;
  }

  private renderPasswordResetPopup() {
    return <PasswordResetPopup languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      signupEmail={this.state.signupEmail}
      showPasswordResetPopupFunc={this.showPasswordResetPopupFunc}
      showSendPasswordResetEmailPopupFunc={this.showSendPasswordResetEmailPopupFunc}
      showSignupPopupFunc={this.showSignupPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
      setResetPasswordEmailFunc={this.setResetPasswordEmailFunc}
    />;
  }

  private renderSendPasswordResetEmailPopup() {
    return <SendPasswordResetEmailPopup languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      resetPasswordEmail={this.state.resetPasswordEmail}
      showSendPasswordResetEmailPopupFunc={this.showSendPasswordResetEmailPopupFunc}
      showLoginPopupFunc={this.showLoginPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
    />;
  }
}

export default withRouter(connect(
  (state: any) => ({
    isExistSsqAccountNotSnsStore: state.root.isExistSsqAccountNotSnsStore,
    loginInfo: state.main.loginInfo,
    loading: state.main.loading,
    showLoginPopup: state.main.showLoginPopup,
  }),
  dispatch => ({
    setExistSsqAccountNotSnsStore: (isExistSsqAccountNotSnsStore: boolean) => dispatch(setExistSsqAccountNotSnsStore(isExistSsqAccountNotSnsStore)),
    setLoginInfo: (info: IfLoginInfo) => dispatch({type: ACTION_TYPES.MAIN.LOGIN_INFO, payload: info}),
    setShowLoginPopup: (b: boolean) => dispatch({type: ACTION_TYPES.MAIN.SHOW_LOGIN_POPUP, payload: b}),
    setLoading: (loading: boolean) => dispatch({type: ACTION_TYPES.MAIN.LOADING, payload: loading}),
    setCountryLang: (country: string, lang: string) => dispatch({type: ACTION_TYPES.MAIN.COUNTRY_LANG, payload: {country, lang}}),
    setDisplayPopupItem: (b: IfDisplayPopupItem) => dispatch({type: ACTION_TYPES.MAIN.DISPLAY_POPUP_ITEM, payload: b}),
    setDisplayBottomPopup: (b: boolean) => dispatch({type: ACTION_TYPES.MAIN.DISPLAY_BOTTOM_POPUP, payload: b}),
  })
)(Header));

const HeaderContainer = styled.div<{ showSearchBoxPopup: boolean }>`
  .allpopupwrap > div {
    transform: inherit;
    top: calc(50% - (64rem / 2));
    left: calc(50% - (36rem / 2));
  }

  z-index: 999;
  background-color: white;
  border-bottom: 1px solid #C7D6EA;
  width: 100%;
  height: 6rem;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;

  @media screen and (max-width: 764px) {
    ${({showSearchBoxPopup}) => showSearchBoxPopup ? 'display: none;' : ''}
    .container {
      height: 100%;
      padding: 0 0 0 1.6rem;
    }
  }
`;

const HeaderContents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
  height: 6rem;

  > div {
    height: 100%;
    line-height: 6rem;
  }

  a {
    text-decoration: none;
    color: #2A2C2F;
  }

  .logo-area {
    width: 23rem;

    .solution-square {

    }

    a {
      display: block;
      height: 6rem;
    }

    img {
      position: relative;
      top: .6rem;
    }

    span {
      ${fontFamilyMedium};
      font-size: 1.2rem;
      color: #90A0B6;
      margin-left: .6rem;
      position: relative;
      top: .6rem;
    }
  }

  .menu-area {
    > div {
      color: #2A2C2F;
      cursor: pointer;
      width: 12rem;
      height: 100%;
      text-align: center;
      vertical-align: center;
      font-size: 1.6rem;
      float: left;

      > a {
        color: #2A2C2F;
        display: block;
        line-height: 6rem;
        position: relative;

        :hover:after {
          display: block;
          content: '';
          background: #407EDB;
          width: 100%;
          height: .4rem;
          position: absolute;
          left: 0;
          bottom: 0;
          animation-duration: 0.3s;
          transform-origin: left;
          animation-name: slideright;
        }
      }
    }

    @keyframes slideright {
      from {
        transform: scaleX(0);
      }

      to {
        transform: scaleX(100%);
      }
    }

    .selected:after {
      background: #407EDB;
      width: 100%;
      height: .4rem;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
    }

    .selected {
      color: #407EDB
    }
  }

  .icon-area {
    //width: 20rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > button {
      height: 100%
    }

    .alarm-active {
      position: relative;
    }

    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #E6003E;
      border-radius: 100%;
      position: absolute;
      right: 0;
    }

    .search-box-btn {
      width: 12rem;
      height: 3.2rem;
      background: #EEF1F6;
      border-radius: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      margin-right: 1.2rem;

      > span {
        ${fontFamilyRegular};
        font-size: 1rem;
        color: #666E7A;
      }

      > img {
        width: 2rem;
        height: 2rem;
      }
    }

    .global-select-header {
      background: url(${SsqImages.header.icon.global_select_icon()}) left center no-repeat;
      margin-right: 1.2rem;
      height: 3.2rem;
      flex-shrink: 0;

      > span {
        ${fontFamilyRegular};
        font-size: 1.2rem;
        color: #2A2C2F;
        padding-left: 2.8rem;
      }
    }

    .my-icon-area {

      width: 2.4rem;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      img {
        vertical-align: middle
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .logo-area {
      width: 26rem;
    }

    .menu-area {
      > div {
        width: 12rem;
      }
    }

    .menu-area {
      display: none;
    }

    .icon-area {
      display: none
    }
  }

  //@media screen and (max-width: 880px) {
  //  .menu-area {
  //    > div {
  //      width: 9rem;
  //    }
  //  }
  //}
  //
  //@media screen and (max-width: 800px) {
  //  .menu-area {
  //    > div {
  //      width: 8rem;
  //
  //      a {
  //        font-size: 1.5rem;
  //      }
  //    }
  //  }
  //}

  @media screen and (max-width: 764px) {
    padding: 0;

    .logo-area {
      //padding-top: 1.9rem;
    }
  }

  @media screen and (max-width: 360px) {
    .logo-area {
      width: 20rem;

      .solution-square {
        display: none;
      }
    }
  }
`;


const MobileMenu = styled.div`
  display: none;
  height: 100%;
  width: 112px;

  @media screen and (max-width: 1199px) {
    display: block;

    .my-icon-area {

      width: 2.4rem;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      img {
        vertical-align: middle
      }
    }

    button {
      vertical-align: middle;
      width: 36px;
      height: 100%;

      .alarm-active {
        position: relative;
        display: inline-block;

        .circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #E6003E;
          border-radius: 100%;
          position: absolute;
          left: 20px;
        }
      }
    }

    button:first-child {
      text-align: left;
    }

    button:last-child {
      padding-right: 8px;
      text-align: left;

      img {
        padding-left: 8px;
      }
    }
  }
  @media screen and (max-width: 764px) {
    justify-content: end;
    display: flex;
    button {
      width: 56px;
    }
  }
  @media screen and (max-width: 360px), (max-width: 360px) and (max-height: 640px) {

  }
  @media screen and (max-width: 280px) {
    button:last-child {
      padding-right: 0;
    }
  }
`;
const SubMenu = styled.div<{ tDiscontinue: string }>`
  width: 100%;
  display: none;
  position: absolute;
  top: 6rem;
  height: auto;
  left: 0;

  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 204px;
    width: 100%;
    background: #fff;
    cursor: default;
    box-shadow: 0px 4px 7px rgb(0 0 0 / 10%);
  }

  &.on {
    display: flex;
    transition: all 0.5s;

    :before {
      display: block;
      transition: all 0.8s;
      animation: menuhover 0.3s forwards;
    }
  }

  @keyframes menuhover {
    from {
      height: 150px;
    }

    to {
      height: 100%;
    }
  }

  > .container {
    display: flex;
    justify-content: space-between;
    padding: 3.5rem 1.6rem;
    z-index: 2;
    animation: fadein 0.7s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .sub-menu-tit {
    font-size: 1.6rem;
    width: 15.5rem;
    text-align: left;
    line-height: initial;
    padding: 1.2rem 0;
  }

  .sub-menu-list {
    width: calc(100% - 18.5rem);

    .sub-menu-cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .sub-menu-cont > div {
      width: 23rem;
      border-radius: 4px;
      margin-bottom: .3rem;
      line-height: initial;
      margin-right: 1.2rem;
      display: flex;
      align-items: center;

      :hover {
        background: rgba(64, 126, 219, 0.1);
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 6.4rem;
        padding: 1.2rem;
      }

      a.sold-out {

        .category-img {
          opacity: .3;
        }

        :after {
          content: "${p => p.tDiscontinue || "단종"}";
          min-width: 3.2rem;
          height: 1.7rem;
          line-height: 1.5rem;
          font-size: .8rem;
          ${fontFamilyMedium};
          z-index: 2;
          color: #7783A1;
          background: #F1F3F6;
          text-align: center;
          border-radius: 2rem;
          padding: 0 0.3rem;
          box-sizing: border-box;
          white-space: nowrap;
          margin-left: 0.4rem;
        }

      }
    }

    .sub-menu-cont-2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      a {
        display: flex;
        align-items: center;
      }
    }

    .sub-menu-cont-2 > a {
      width: 45.3rem;
      height: 6.4rem;
      border-radius: 4px;
      margin-bottom: .3rem;
      padding: 1.2rem;
      line-height: initial;
      margin-right: 1.2rem;

      :hover {
        background: rgba(64, 126, 219, 0.1);
      }
    }

    .sub-menu-cont-2s {
      width: 31%;
      float: right;
      text-align: left;
    }

    .category-img {
      width: 4rem;
      height: 4rem;
      line-height: 4rem;
      background: rgba(64, 126, 219, 0.1);
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      &.community-service {
        background: transparent;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .category-name {
      margin-left: 1.2rem;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: left;
      ${fontFamilyRegular};

      .container {
        display: flex;
        align-items: center;
      }

      .title {
        white-space: nowrap;
      }

      .rnd-mark {
        white-space: nowrap;
        margin-left: 1rem;
        height: 1.6rem;
        color: #FFFFFF;
        background: #7783A1;
        padding: 0 1.4rem;
        font-size: 9px;
        border-radius: 16px;
        ${fontFamilyMedium};
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .partner-mark {
        white-space: nowrap;
        margin-left: 1rem;
        height: 1.6rem;
        color: #FFFFFF;
        background: #F4B209;
        padding: 0 1rem;
        font-size: 9px;
        border-radius: 16px;
        ${fontFamilyMedium};
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        display: block;
        color: #666E7A;
        font-size: 1.2rem;
      }
    }
  }

  .half-menu.sub-menu-list {
    > div {
      width: calc(50% - 6px);
      display: inline-block;

      + div {
        margin-left: 1.2rem;
      }

      .type-tit {
        background: #EEF1F6;
        border-radius: 4px;
        color: #666E7A;
        font-size: 1rem;
        line-height: 2rem;
        text-align: left;
        padding: 0 1.2rem;
        margin-bottom: .4rem;
      }
    }

    .sub-menu-cont > div {
      width: calc(50% - 12px);
    }
  }


  // 제품메뉴 서브메뉴 제품선정 가이트 스타일

  &.product-guide-menu {
    flex-direction: column;

    .product-guide {
      width: 100%;
      height: 8.8rem;
      display: flex;
      align-items: center;
      z-index: 2;
      border-top: 1px solid #D9D9D9;
      animation: fadein 0.7s;

      .pg-container {
        max-width: 120rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 1.6rem;

        .sub-menu-list {

          button.link {
            width: 16px;
            height: 16px;
            vertical-align: middle;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1199px) {
  .sub-menu-tit {
    display: none;
  }

  .sub-menu-list {
    width: 100%
  }
}
`;

const UserPopupWrap = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;

  .UserPopupContent {
    max-width: 120rem;
    margin: 0 auto;
    position: relative;
  }

  @media screen and (max-width: 1199px) {
    .UserPopupContent.mobile {
      display: block;
    }

    .UserPopupContent.non_mobile {
      display: none
    }
  }
`;
const UserPopup = styled.div`
  background: white;
  font-size: 1.5rem;
  width: 24rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  color: #2A2C2F;

  &.country-select {
    width: auto;
    min-width: 11rem;
    right: 3rem;

    button {
      height: 4.8rem;
      line-height: 4.8rem;
      display: block;
      padding: 0 1.6rem;
      border-bottom: .1rem solid #C8D6EA;

      span.bold {
        ${fontFamilyMedium};
        font-size: 1.2rem;
        color: #2A2C2F;
        padding-right: .6rem;
      }

      span.light {
        ${fontFamilyRegular};
        font-size: 1rem;
        color: #7783A1;
      }
    }

    button:last-child {
      border-bottom: none;
    }
  }

  @media screen and (min-width: 765px) {
    animation-duration: 0.5s;
    animation-name: popupbottom;
    @keyframes popupbottom {
      from {
        transform: translateY(-100%)
      }

      to {
        transform: translateY(0);
      }
    }
  }
  @media screen and (max-width: 1199px) {
    animation: MenuOpen 0.5s;

    @keyframes MenuOpen {
      from {
        transform: translateX(100%);
      }

      to {
        transform: translateX(0);
      }
    }
  }

  > div {
    border-bottom: solid 1px #C7D6EA;

    :hover {
      background: rgba(64, 126, 219, 0.1);
    }

    :active {
      background: rgba(64, 126, 219, 0.3);
    }

    :last-child {
      border-bottom: none
    }

    > button {
      width: 100%;
      font-size: 1.2rem;
      text-align: left;
      color: #2A2C2F;
      ${fontFamilyMedium};

      a {
        display: block;
        width: 100%;
        color: #2A2C2F;
        padding: 1.6rem;
      }

      img {
        vertical-align: middle;
        margin-right: .8rem
      }
    }
  }

  //@media screen and (max-width: 1199px) {
  //  overflow-y: auto;
  //}

  @media screen and (max-width: 1199px) {
    height: 100%;
    position: fixed;
    width: 32rem;
    max-width: 90%;
    overflow: auto;
    padding-bottom: 8rem;
    margin-top: 6rem;

    > div {
      > button {
        font-size: 1.6rem;

        a {
          padding: 1.5rem 1.7rem;
          font-size: 1.6rem;
        }
      }
    }
  }
  @media screen and (max-width: 764px) {
    top: 5.4rem;
    margin-top: 0;
    > div {
      button img {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
`;
const UserInfo = styled.div`

  .login_btn {
    padding: 1.3rem;
  }

  .user_info_div {
    display: block;
    padding: 1.6rem;
    color: #2A2C2F;
    cursor: pointer;
  }

  .user_info {
    position: relative;
    margin-top: 0.4rem;

    > img {
      margin-right: .8rem;
      margin-bottom: .4rem;
    }

    > div {
      width: 100%;
      text-align: center;

      .name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        line-height: 2rem;
        margin-bottom: .6rem;
        color: #2A2C2F;

        span {
          display: inline-block;
          height: 2rem;
          line-height: 2rem;
          padding: 0 1.2rem;
          background: #7C899E;
          color: #fff;
          border-radius: 2rem;
          font-size: 1.2rem;
          margin-left: 0.4rem;
          ${fontFamilyMedium};
        }

        span.first {
          background: #EDAE0A;
        }

        span.second {
          background: #FF7A00;
        }

        span.third {
          background: #7B5DF1;
        }

        span.fourth {
          background: #407EDB;
        }

        span.fifth {
          background: #7C899E;
        }
      }

      .name_alt {
        display: none;
        font-size: 12px;
        color: #fff;
        background: rgba(42, 44, 47, 0.5);
        border-radius: 4px;
        max-width: 208px;
        padding: .8rem 1.2rem;
        text-align: left;
        ${fontFamilyRegular};
        font-style: normal;
        z-index: 9;
        position: absolute;
        left: 40%;
      }

      :hover {
        .name_alt {
          display: inline-block;
        }
      }
    }

    .user_level {
      display: inline-block;
      width: 8rem;
      height: 2.4rem;
      line-height: 2.4rem;
      text-align: center;
      background: #fff;
      border: 1px solid #C7D6EA;
      border-radius: 100px;
      color: #7783A1;
      font-size: 1rem;
      position: absolute;
      right: 0;
      top: calc(50% - 1.2rem)
    }

    .my-alarm-txt {
      color: #7783A1;
      ${fontFamilyRegular};
      font-size: 1rem;

      span.alarm-counter {
        color: #E6003E;
        ${fontFamilyMedium};
      }
    }


  }

  @media screen and (max-width: 764px) {
    .login_btn {
      padding: 1.7rem;
    }

    > a {
      padding: 1.7rem;
    }

    .user_info {
      p {
        font-size: 1.6rem;
      }

      span {
        //font-size: 1.2rem;
      }

      .user_level {
        font-size: 1.4rem;
        height: 3.2rem;
        line-height: 3.2rem;
      }
    }
  }
`;
const UserMypage = styled.div`
  .alarm-active {
    position: relative;
    display: inline-block;

    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #E6003E;
      border-radius: 100%;
      position: absolute;
      left: 20px;
    }
  }
`;
const UserPostpage = styled.div`

`;
const Userstate = styled.div`
  button {
    padding: 1.6rem;
  }

  @media screen and (max-width: 764px) {
    button {
      padding: 1.7rem;
    }
  }

`;
const UserLanguage = styled.div`
  //display: flex;

  :hover {
    background: #fff !important;
  }

  padding: 0;
  border-bottom: solid 1px #C7D6EA;

  .country-select-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.7rem;

    :hover {
      background: rgba(64, 126, 219, 0.1);
    }

    &.isopen {
      img.btn {
        transform: rotate(-90deg);
      }
    }

    img.icon {
      width: 3.2rem;
      height: 3.2rem;
      margin-right: .8rem;
    }

    img.btn {
      transform: rotate(90deg);
      transition: transform 0.3s linear;
    }

    > div {
      display: flex;
      align-items: center;

      .global-name {
        > p:first-child {
          font-size: 1.6rem;
          ${fontFamilyMedium};
        }

        > p:last-child {
          font-size: 1.2rem;
          ${fontFamilyRegular};
          color: #7783A1;
        }
      }
    }
  }

  .country-select-list {
    overflow: hidden;


    > div {
      padding: 0 1.7rem 0 5.7rem;
      height: 0;
      opacity: 0;
      transition: all 0.3s linear;

      :hover {
        background: rgba(64, 126, 219, 0.1);
      }
    }

    &.open {
      > div {
        height: 4rem;
        opacity: 1;
      }

      > div:first-child {
        margin-top: .5rem;
      }

      > div:last-child {
        margin-bottom: .5rem;
      }
    }

  }


  > div:first-child {
    //width: 27%;
    //border-radius: 100px 0 0 100px;
  }

  > div:last-child {
    //width: 46%;
    //border-radius: 0 100px 100px 0;
  }

  button {
    width: 100%;
    color: #7783A1;
    line-height: 3rem;
    text-align: left;

    > span:first-child {
      font-size: 1.5rem;
      ${fontFamilyMedium};
      margin-right: .8rem;
    }

    > span:last-child {
      font-size: 1.2rem;
      ${fontFamilyRegular};
    }
  }

  .selected {
    //background: #407EDB;
  }

  @media screen and (max-width: 764px) {

    button {
      line-height: 3.4rem;
    }
  }
`;
const UserAdmin = styled.div`
`;


const MobileMenuList = styled.div`

  span {
    font-size: 12px;
    color: #909FB7;
    padding: 4px 8px;
    display: block;
  }

  :hover, :active {
    background: none !important;
  }

  ul {
    li {
      text-align: left;
      ${fontFamilyMedium};
      border-bottom: solid 1px #C7D6EA;

      a {
        display: block;
        width: 100%;
        padding: 1rem;
        color: #2A2C2F;
        font-size: 1.2rem;
      }

      img {
        vertical-align: middle;
        margin-right: .8rem;
      }

      :last-child {
        border-bottom: none;
      }

      :hover {
        background: rgba(64, 126, 219, 0.1);
      }

      :active {
        background: rgba(64, 126, 219, 0.3);
      }
    }

    li.community-main {
      border-bottom: none;
    }

    li.service-main {
      border-bottom: none;
    }

    li.submenu {
      text-align: left;
      ${fontFamilyMedium};
      border-bottom: none;

      a {
        display: block;
        width: 100%;
        padding: 1rem 5rem;
        color: #7783A1;
        font-size: 1.5rem;
      }
    }

    li.community-last {
      border-bottom: solid 1px #C7D6EA;
      padding-bottom: 1rem;

      :hover {
        background: none;
      }
    }
  }

  @media screen and (max-width: 764px) {
    ul li {
      a {
        font-size: 1.6rem;
        padding: 1.5rem 1.7rem;

        img {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      &.submenu a {
        padding: 0 2rem 0 5.7rem !important;
        font-size: 1.5rem;
        line-height: 3.6rem;

        img {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
`;
