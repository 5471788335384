import {resi18nutil} from "language/resi18nutil";
import {RES_ALL, resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_admin_page = {
  I18N_TITLE_MAIN: ["메인", "Main", undefined],
  I18N_TITLE_SETTINGS: ["관리자 설정", "Admin Settings", undefined],
  I18N_ADD_BTN: ["추가", "Add", undefined],
  I18N_TAB_TECHNICAL_TAB: ["기술문서 관리", "Technical article", undefined],
  I18N_TAB_SOFTWARE_TAB: ["SW 관리", "Software", undefined],
  I18N_TAB_DOCUMENT_TAB: ["자료 관리", "Document", undefined],
  I18N_TAB_CATEGORY_TAB: ["카테고리 관리", "Category", undefined],
  I18N_TAB_COMMUNITY_TAB: ["커뮤니티 관리", "Community", undefined],
  I18N_TAB_USER_TAB: ["사용자 관리", "User", undefined],
  I18N_TAB_FEEDBACK_TAB: ["피드백 관리", "Feedback", undefined],
  I18N_TAB_POPUP_TAB: ["팝업 관리", "Popup", undefined],
  I18N_TAB_URL_TAB: ["URL 관리", "URL", undefined],
  I18N_TAB_LOG_TAB: ["로그 관리", "Log", undefined],
  I18N_TAB_PARTNER_TAB: ["파트너 관리", "Partner", undefined],
  I18N_TAB_SEARCH_TAB: ["검색 관리", "Search", undefined],
  I18N_TAB_PRODUCT_TAB: ["제품", "Product", undefined],
  I18N_TAB_INDUSTRY_TAB: ["산업", "Industry", undefined],
  I18N_TAB_APP_TAB: ["적용 부하", "Application", undefined],
  I18N_LANGUAGE_SETTING_KOREA: ["한국어", "Korean", undefined],
  I18N_LANGUAGE_SETTING_ENGLISH: ["English", "English", undefined],
  I18N_BUTTON_RESET: ["리셋", "Reset", undefined],
  I18N_BUTTON_DELETE: ["삭제", "Delete", undefined],
  I18N_BUTTON_PREVIEW: ["미리보기", "Preview", undefined],
  I18N_BUTTON_TEMP_DELETE: ["삭제", "Delete", undefined],
  I18N_BUTTON_TEMP_SAVE: ["임시 저장", "Temp Save", undefined],
  I18N_BUTTON_SAVE: ["저장", "Save", undefined],
  I18N_BUTTON_REQUEST: ["등록 요청", "Request", undefined],
  I18N_BUTTON_APPR_REQUEST: ["승인 요청", "Request", undefined],
  I18N_BUTTON_REQUEST_MOD: ["수정 요청", "Request", undefined],
  I18N_BUTTON_CANCEL_REQ: ["승인 요청 취소", "Cancel Request", undefined],
  I18N_BUTTON_CANCEL_DEL_REQ: ["삭제 요청 취소", "Cancel Deletion", undefined],
  I18N_DOCWRITE_CANCEL_REQ_ALERT: ['승인 요청이 취소되고, 수정 화면으로 전환됩니다. 전환 후 저장하지 않으면 내용은 소실됩니다. 계속하시겠습니까?',
    'The request will be canceled and this window will be changed to edit mode. Do you want to continue?', undefined],
  I18N_DOCWRITE_CANCEL_DEL_REQ_ALERT: ['삭제요청이 취소됩니다. 계속하시겠습니까?', 'The delete request will be canceled. Do you want to continue?', undefined],
  I18N_BUTTON_REJECT: ["반려", "Reject", undefined],
  I18N_BUTTON_CANCEL: ["취소", "Cancel", undefined],
  I18N_BUTTON_APPROVE: ["승인", "Approve", undefined],
  I18N_BUTTON_CHECK: ["확인", "Confirm", undefined],
  I18N_SUBJECT_CATEGORY_KOREA: ["카테고리 명 (한국어)", "Category Name (Korean)", undefined],
  I18N_SUBJECT_FEATURE_KOREA: ["속성 (한국어)", "Feature (Korean)", undefined],
  I18N_SUBJECT_DESCRIPTION_KOREA: ["설명 (한국어)", "Description (Korean)", undefined],
  I18N_SUBJECT_CATEGORY: ["카테고리 명", "Category Name", undefined],
  I18N_SUBJECT_FEATURE: ["속성", "Feature", undefined],
  I18N_SUBJECT_DESCRIPTION: ["설명", "Description", undefined],
  I18N_SUBJECT_DISCONTINUE: ["단종", "Discontinued", undefined],
  I18N_SUBJECT_CATEGORY_ENGLISH: ["카테고리 명 (영어)", "Category Name (English)", undefined],
  I18N_SUBJECT_FEATURE_ENGLISH: ["속성 (영어)", "Feature (English)", undefined],
  I18N_SUBJECT_DESCRIPTION_ENGLISH: ["설명 (영어)", "Description (English)", undefined],
  I18N_SUBJECT_HOMEPAGE_LINK: ["홈페이지 링크", "Homepage Link", undefined],
  I18N_SUBJECT_GOTO_HOMEPAGE_LINK: ["홈페이지 바로가기", "Go to the homepage", undefined],
  I18N_SUBJECT_MODEL: ["모델", "Model", undefined],
  I18N_PLACEHOLDER_UNDER_SHORT_TEXT: ["10자 이내 텍스트", "Max. 15 characters", undefined],
  I18N_PLACEHOLDER_UNDER_LONG_TEXT: ["100자 이내 텍스트", "Max. 150 characters", undefined],
  I18N_PLACEHOLDER_MODEL_NAME: ["ex)XGT-CPUUN", "ex)XGT-CPUUN", undefined],
  I18N_PLACEHOLDER_TEXT_LIMIT: ["{textLimit}자 이내 텍스트", "Max. {textLimit} characters", undefined],
  I18N_ERROR_MESSAGE_TEXT_LIMIT: ["{textLimit}자 이하의 텍스트를 입력해 주세요.", "Max. {textLimit} characters", undefined],
  I18N_ERROR_MESSAGE_PARTNER_NAME: ["중복 또는 공란은 허용하지 않습니다.", "Do not allow duplicates or blanks", undefined],
  I18N_MODEL_ALREADY_EXIST: ["이미 등록된 모델 입니다.", "The model already exists.", undefined],
  I18N_PLACEHOLDER_SAVED: ["저장 되었습니다", "Successfully Registered.", undefined],
  I18N_PLACEHOLDER_SAVED_FAIL: ["저장에 실패하였습니다.", "Failed to save.", undefined],
  I18N_PLACEHOLDER_INPUT_MANDATORY: ["필수 정보를 입력해 주세요", "Required input are empty.", undefined],
  I18N_PLACEHOLDER_DELETE: ["카테고리 정보가 삭제됩니다", "Category information will be deleted", undefined],
  I18N_PLACEHOLDER_DELETE_SUBCATEGORY: ["하부 카테고리 삭제가 필요합니다", "Delete sub-categories.", undefined],
  I18N_MODEL_NAME_ALERT: ["영문, 숫자, 특수기호 /,()- 만 입력 가능합니다.", "Characters with a mix of letters, numbers and special symbols.", undefined],
  I18N_ALL: ["전체", "All", undefined],
  I18N_SELECT_LEVEL: ["%d분류 선택", "%d category", undefined],
  I18N_NO_CATEGORY_DOCUMENT: ["카테고리 미지정 자료만 보기", "See documents not Categorized only.", undefined],
  I18N_TAB_MANUAL: ["사용 설명서", "User Manuals", undefined],
  I18N_TAB_CATALOG: ["카탈로그", "Catalogs", undefined],
  I18N_TAB_SOFTWARE: ["소프트웨어", "Software", undefined],
  I18N_TAB_CERTIFICATION: ["인증/성적서", "Certificates", undefined],
  I18N_TAB_CAD_DATA: ["CAD/배선도", "CAD/Wiring", undefined],
  I18N_TAB_DATASHEET: ["데이터시트", "Datasheets", undefined],
  I18N_TAB_OS: ["OS/펌웨어", "OS/Firmware", undefined],
  I18N_TAB_APPLICATION_NOTE: ["Application Note", "Application Note", undefined],
  I18N_TAB_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guide", undefined],
  I18N_TAB_SAMPLE: ["Sample", "Sample", undefined],
  I18N_TAB_EDUCATION_NOTE: ["교육 자료", "Training Manual", undefined],
  I18N_TAB_EDUCATION_VIDEO: ["교육 동영상", "Training Video", undefined],
  I18N_TAB_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
  I18N_TAB_TROUBLE_SHOOTING: ["Troubleshooting", "Troubleshooting", undefined],
  I18N_TAB_OPEN_BATCH_SETTING: ["공개 일괄 변경", "Public All", undefined],
  I18N_TAB_TARGET_BATCH_SETTING: ["대상 일괄 변경", "Change All", undefined],
  I18N_TAB_BATCH_DELETE: ["일괄 삭제", "Delete All", undefined],
  I18N_TAB_POST_DOCUMENT: ["자료 등록", "Register", undefined],
  I18N_TAB_POST_TECHNICAL: ["기술문서 등록", "Register", undefined],
  I18N_TAB_POST_SOFTWARE: ["SW 등록", "Register", undefined],
  I18N_TAB_POST_COMMUNITY: ["글쓰기", "Write", undefined],
  I18N_TAB_PRODUCT_CATEGORY: ["제품 카테고리", "Product", undefined],
  I18N_TAB_PRODUCT: ["제품", "Product", undefined],
  I18N_TAB_PRODUCT_CATEGORY_DESC: ["하위 분류를 전체로 선택할 경우 하위 카테고리 전체 제품에서 해당 문서가 검색됩니다.", "If the sub-classification is selected as a whole, the document is retrieved from the entire subcategory product.", undefined],
  I18N_TAB_ALL_TAG: ["태그", "Tag", undefined],
  I18N_TITLE_DOCUMENT_MANAGEMENT: ["자료 관리", "Document Management", undefined],
  I18N_TITLE_DOCUMENT_ACCEPT: ["자료 승인", "Document Approval", undefined],
  I18N_TITLE_TECHNICAL_ACCEPT: ["기술문서 승인", "Technical Article Approval", undefined],
  I18N_ACCEPT_ALL: ["일괄 승인", "Approve All", undefined],
  I18N_FILTER_INDUSTRY_CATEGORY: ["산업 카테고리", "Industry", undefined],
  I18N_FILTER_INDUSTRY: ["산업", "Industry", undefined],
  I18N_FILTER_APPLICATION_LOAD_CATEGORY: ["적용 부하 카테고리", "Application", undefined],
  I18N_FILTER_APPLICATION_LOAD: ["적용/부하", "Application", undefined],
  I18N_TITLE_LIST_EMPTY: ["제목을 설정하여 목차를 구성할 수 있습니다.", "The table of contents is automatically configured with title", undefined],
  I18N_FILTER_DOCUMENT_TYPE: ["자료 구분", "Type", undefined],
  I18N_FILTER_DOCUMENT_TITLE: ["자료명", "Title", undefined],
  I18N_PLACEHOLDER_INPUT_TEXT: ["텍스트 입력", "Text input", undefined],
  I18N_PLACEHOLDER_INPUT_TITLE_WRITER: ["제목/작성자", "Title/Writer", undefined],
  I18N_FILTER_OWN_DOCUMENT: ["내 문서만 보기", "See only my documents", undefined],
  I18N_FILTER_EXPOSE_STATE: ["공개 상태", "Visibility", undefined],
  I18N_FILTER_ADMISSION_STATE: ["승인 상태", "Approval Status", undefined],
  I18N_FILTER_DOC_STATE: ["문서 상태", "Status", undefined],
  I18N_FILTER_FINISHED_STATE: ["진행 상태", "Status", undefined],
  I18N_FILTER_TARGET: ["대상", "User Level", undefined],
  I18N_FILTER_SITE_LANGUAGE: ["국가", "Site Language", undefined],
  I18N_FILTER_REGION_LANGUAGE: ["사이트", "Region", undefined],
  I18N_FILTER_FILE_LANGUAGE: ["첨부파일 언어", "Attachment", undefined],
  I18N_FILTER_ALL: ["전체", "All", undefined],
  I18N_FILTER_COMMON: ["공용", "All", undefined],
  I18N_FILTER_KOREAN: ["한국어", "Korean", undefined],
  I18N_FILTER_ENGLISH: ["영어", "English", undefined],
  I18N_FILTER_ENGLISH_US: ["영어(US)", "English(US)", undefined],
  I18N_FILTER_KOREA: ["대한민국", "Korea", undefined],
  I18N_FILTER_GLOBAL: ["Global", "Global", undefined],
  I18N_FILTER_USA: ["USA", "USA", undefined],
  I18N_FILTER_ETC: ["기타", "Etc.", undefined],
  I18N_FILTER_NO_ATTACHMENT: ["첨부 없음", "Empty", undefined],
  I18N_FILTER_CLOSE_FILTER: ["필터 닫기", "Close Filter", undefined],
  I18N_FILTER_VIEW_MORE_FILTER: ["필터 더보기", "View Filter", undefined],
  I18N_FILTER_GUEST: ["게스트", "Guest", undefined],
  I18N_FILTER_LOGIN: ["로그인", "Login User", undefined],
  I18N_FILTER_PARTNER: ["파트너", "Partner", undefined],
  I18N_FILTER_EMPLOYEE: ["임직원", "Employee", undefined],
  I18N_FILTER_APPROVED: ["승인", "Approved", undefined],
  I18N_FILTER_NOT_APPROVED: ["승인중", "Progressing", undefined],
  I18N_FILTER_DEL_REQUESTED: ["삭제중", "Deleting", undefined],
  I18N_FILTER_DELETED: ["삭제", "Delete", undefined],
  I18N_FILTER_REJECT: ["반려", "Reject", undefined],
  I18N_FILTER_EXPOSE: ["공개", "Public", undefined],
  I18N_FILTER_NOT_FINISHED: ["진행중", "Progressing…", undefined],
  I18N_FILTER_FINISHED: ["완료", "Completed", undefined],
  I18N_TAG_TEMP: ["임시", "Temp", undefined],
  I18N_REQUIRED: ["필수 입력값", "Required fields", undefined],
  I18N_MODIFIED: ["수정", "Modify", undefined],
  I18N_CREATED: ["작성", "Write", undefined],
  I18N_DOWNLOAD_COUNT: ["다운로드수", "Download Count", undefined],
  I18N_FILTER_NOT_EXPOSE: ["비공개", "Private", undefined],
  I18N_DISPLAY_SHORT_KOREAN: ["한", "Ko", undefined],
  I18N_DISPLAY_SHORT_ENGLISH: ["영", "En", undefined],
  I18N_DISPLAY_SHORT_ENGLISH_US: ["영(US)", "En(US)", undefined],
  I18N_DOC_VIEW_MORE: ["더 보기", "View More", undefined],
  I18N_FILTER_FILE_LANG_KO: ["한", "Ko", undefined],
  I18N_FILTER_FILE_LANG_EN: ["영", "En", undefined],
  I18N_FILTER_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_FILTER_FILE_LANG_ALL: ["공용", "All", undefined],
  I18N_ATTACHED_FILE: ["첨부 파일", "Attachment", undefined],
  I18N_ADD_ATTACHED_FILE: ["첨부파일 추가", "Add Attachment", undefined],
  I18N_ADD_CATEGORY: ["카테고리 추가", "Add Category", undefined],
  I18N_MANAGE_VERSION: ["버전 관리", "Version", undefined],
  I18N_NOT_USE: ["미사용", "Not Use", undefined],
  I18N_USE: ["사용", "Use", undefined],
  I18N_ATTACH_ALERT: ["첨부 파일은 최대 10개까지 가능합니다.", "A User can add 10 files.", undefined],
  I18N_FILE_VERIFY_ERR: ["업로드에 실패하였습니다. 다시 올려주세요.", "Your upload failed. Please try again.", undefined],
  I18N_ADD_VERSION: ["버전 등록", "Register", undefined],
  I18N_VERSION_ALERT: ["중복된 버전이 있습니다.", "The version is duplicate", undefined],
  I18N_VERSION_EMPTY: ["버전을 입력하세요.", "Write version number", undefined],
  I18N_EMPTY_ALERT: ["내용을 입력해 주세요.", "Write Text", undefined],
  I18N_SELECT_ALERT: ["선택해 주세요.", "Select the option", undefined],
  I18N_NO_TITLE: ["제목 없음", "No Title", undefined],
  I18N_VERSION: ["버전", "Version", undefined],
  I18N_VERSION_LETTER_ERROR_MESSAGE: ["버전은(숫자/영문/. /- )로 최대 15 자리가 입력 가능합니다.", "Max. 15 characters can be entered (number, alphabet , . , -).", undefined],
  I18N_UPDATE_TIME: ["업데이트", "Update", undefined],
  I18N_WRITE_DATE: ["작성일", "Date of writing", undefined],
  I18N_LANGUAGE: ["언어", "Language", undefined],
  I18N_SITE: ["사이트", "Site", undefined],
  I18N_COMMENT: ["의견", "Comment", undefined],
  I18N_MODIFIER: ["수정자", "Modifier", undefined],
  I18N_WRITER: ["작성자", "Writer", undefined],
  I18N_STATE: ["상태", "Visibility", undefined],
  I18N_SEARCH_RESULT: ["조회 결과", "Result", undefined],
  I18N_SEARCH_RESULT_COUNT_UNIT: ["개", "Count", undefined],
  I18N_REQUIRE_MEMBER_ERROR_TIT: ["회원 전용 페이지 입니다.", "Members only", undefined],
  I18N_REQUIRE_MEMBER_ERROR_TXT: ["회원가입 또는 로그인해 주세요.", "Sign up today or Login", undefined],
  I18N_REQUIRE_MEMBER_ERROR_BUTTON: ["회원가입 또는 로그인하기", "Sign Up or Login", undefined],
  I18N_REQUIRE_MEMBER_ERROR_INFO: ["회원가입은 여기에 있어요!", "Sign Up here!", undefined],
  I18N_REQUIRE_EMPLOYEE_ERROR_TIT: ["임직원 또는 관리자 전용 페이지 입니다.", "Employee or Admin page.", undefined],
  I18N_REQUIRE_EMPLOYEE_ERROR_TXT: ["임직원 또는 관리자로 로그인하세요.", "Login as Employee or Admin", undefined],
  I18N_REQUIRE_EMPLOYEE_ERROR_ALERT: ["임직원 전용 페이지입니다. 로그인하신 권한으로는 접근할 수 없습니다.", "Employee or Admin page. Login as Employee or Admin.", undefined],
  I18N_REQUIRE_EMPLOYEE_ALERT: ["임직원 전용 페이지입니다.", "Employee or Admin page.", undefined],
  I18N_REQUIRE_RND_ALERT: ["추가 권한이 필요한 서비스 입니다.", "EN_추가 권한이 필요한 서비스 입니다.", undefined],
  I18N_REQUIRE_PARTNER_ERROR_TIT: ["파트너 전용 페이지 입니다.", "Partner only page.", undefined],
  I18N_REQUIRE_PARTNER_ERROR_TXT: ["파트너 전용 계정으로 로그인하세요.", "Login as Partner account.", undefined],
  I18N_REQUIRE_PARTNER_ERROR_ALERT: ["파트너 전용 페이지입니다. 로그인하신 권한으로는 접근할 수 없습니다.", "Partner only page. Login as Partner account.", undefined],
  I18N_REQUIRE_PARTNER_ALERT: ["파트너 전용 페이지입니다.", "Partner only page.", undefined],
  I18N_REQUIRE_EMPLOYEE_ERROR_BUTTON: ["로그인하기", "Login", undefined],
  I18N_REQUIRE_EMPLOYEE_ERROR_INFO: ["임직원 로그인은 여기에 있어요!", "Employee Login here!", undefined],
  I18N_EMPLOYEE_LIST_TIT: ["임직원 목록", "Employee list", undefined],
  I18N_EMPLOYEE_LIST_NICKNAME: ["별명(성명)", "Nickname", undefined],
  I18N_EMPLOYEE_LIST_TEAM: ["부서/직위", "Department<br/>/ Job level", undefined],
  I18N_EMPLOYEE_LIST_MAIL: ["메일 주소", "E-mail", undefined],
  I18N_EMPLOYEE_LIST_EXPERT: ["Expert", "Expert", undefined],
  I18N_EMPLOYEE_LIST_RND: ["R&D", "R&D", undefined],
  I18N_EMPLOYEE_LIST_DOCUMENT_MANAGE: ["자료 관리", "Document Management", undefined],
  I18N_EMPLOYEE_LIST_DOCUMENT_APPROVED: ["자료 승인", "Document Approval", undefined],
  I18N_EMPLOYEE_LIST_TECH_MANAGE: ["기술문서 관리", "Technical Article Management", undefined],
  I18N_EMPLOYEE_LIST_TECH_APPROVED: ["기술문서 승인", "Technical Article Approval", undefined],
  I18N_EMPLOYEE_LIST_SYSTEM_MANAGE: ["시스템 관리", "System", undefined],

  I18N_EMPLOYEE_LIST_ADMIN_KOKR: ["국내<br/>문서 관리", "Korea<br/>Document", undefined],
  I18N_EMPLOYEE_LIST_ADMIN_EN: ["글로벌<br/>문서 관리", "Global<br/>Document", undefined],
  I18N_EMPLOYEE_LIST_ADMIN_ENUS: ["북미<br/>문서 관리", "USA<br/>Document", undefined],
  I18N_EMPLOYEE_LIST_ADMIN_SW: ["SW 관리", "Software", undefined],

  I18N_EMPLOYEE_LIST_ACCEPT_PRIVATE: ["수집 이용", "Accept Private Information", undefined],
  I18N_EMPLOYEE_LIST_ACCEPT_MAIL: ["메일 수신", "Accept Mail", undefined],
  I18N_EMPLOYEE_LIST_EXPORT_MARKETING: ["마케팅<br/> 활용 동의", "Allow<br/> Marketing", undefined],
  I18N_EMPLOYEE_LIST_RECENT_LOGIN: ["최근로그인", "Last login", undefined],
  I18N_DOC_WRITE_CONFIRM_TEMPLATE: ["유형 변경시  새 템플릿이 적용되어 작성 내용이 삭제 됩니다. 변경 하시겠습니까?", "Changing the type, the new template is applied and the creation is deleted. Are you sure you want to change it?", undefined],
  I18N_USER_LIST_TIT: ["사용자 목록", "User list", undefined],
  I18N_USER_LIST_ADMIN: ["관리자", "Admin", undefined],
  I18N_USER_LIST_SEARCH: ["별명/성명/메일 주소", "Nickname/E-mail", undefined],
  I18N_USER_LIST_BTN: ["관리자로 추가", "Register Admin", undefined],
  I18N_USER_LIST_BTN02: ["패스워드 초기화", "Rest Password", undefined],
  I18N_USER_LIST_BTN03: ["삭제", "Delete", undefined],
  I18N_USER_LIST_BTN04: ["엑셀 내보내기", "Export CSV", undefined],
  I18N_USER_LIST_EXPORT_TYPE: ["내보내기", "Export CSV File", undefined],
  I18N_USER_LIST_EXPORT_DESCRIPTION: ["사용자 종류를 선택해 주세요.<br/>‘마케팅 활용 동의자’ 선택 시 마케팅 활용에 동의한<br/>사용자만 다운로드 됩니다.", "Please select the type of user. <br/>If you select 'Consent Marketing', only users who have<br/>consented to marketing use will be downloaded. ", undefined],
  I18N_USER_LIST_EXPORT_ALL: ["전체 사용자", "All Users", undefined],
  I18N_USER_LIST_EXPORT_MARKETING: ["마케팅 활용", "Consent Marketing", undefined],
  I18N_USER_LIST_STATE: ["상태", "Status", undefined],
  I18N_USER_LIST_LEVEL: ["구분", "User", undefined],
  I18N_USER_LIST_NICKNAME: ["별명", "Nickname", undefined],
  I18N_USER_LIST_MAIL: ["메일 주소", "E-mail", undefined],
  I18N_USER_LIST_EXPERT: ["Expert", "Expert", undefined],
  I18N_USER_LIST_ACCEPT_PRIVATE: ["수집 이용", "Accept Private Information", undefined],
  I18N_USER_LIST_MAILING: ["메일 수신", "Accept Mail", undefined],
  I18N_USER_LIST_TECH_POINT: ["기술 점수", "Tech Point", undefined],
  I18N_USER_LIST_COUNT_LOGIN: ["로그인 수", "Login Count", undefined],
  I18N_USER_LIST_SIGNUP_DATE: ["가입일", "Registration date", undefined],
  I18N_USER_LIST_RECENT_LOGIN: ["최근로그인", "Last login", undefined],
  I18N_USER_LIST_DELETE_CONFIRM: ["해당 계정의 정보가 삭제됩니다.", "This account will be deleted.", undefined],
  I18N_FEEDBACK_MYFEEDBACK: ["내 문서만 보기", "See only my documents", undefined],
  I18N_FEEDBACK_CONFIRM: ["조치 완료", "Confirm", undefined],
  I18N_FEEDBACK_CONFIRM_ALERT: ["피드백에 대해 조치 완료 상태로 변경됩니다.", "The feedback will be changed to completed status.", undefined],
  I18N_FEEDBACK_TYPE: ["구분", "Feedback", undefined],
  I18N_FEEDBACK_COMMENT: ["내용", "Comment", undefined],
  I18N_FEEDBACK_DATE: ["작성일", "Date", undefined],
  I18N_FEEDBACK_WRITER: ["작성자", "Writer", undefined],
  I18N_FEEDBACK_FILE: ["자료명", "Related Document", undefined],
  I18N_FEEDBACK_RESULT: ["자료 피드백", "Document Feedbacks", undefined],
  I18N_FEEDBACK_MODIFIER: ["수정자", "Modifier", undefined],
  I18N_FEEDBACK_TARGET: ["대상자", "User", undefined],
  I18N_FEEDBACK_OF_DOCUMENT: ["피드백 모아보기", "All Feedbacks", undefined],
  I18N_DOC_FEEDBACK_GOOD_STRING: ["좋아요", "Good", undefined],
  I18N_DOC_FEEDBACK_BAD_STRING: ["싫어요", "Bad", undefined],
  I18N_DOC_FEEDBACK_GOOD_STRING2: ["도움돼요", "Helpful", undefined],
  I18N_DOC_FEEDBACK_BAD_STRING2: ["부족해요", "Not Helpful", undefined],
  I18N_DOC_FEEDBACK_RPT_COMMENT_STRING: ["댓글신고", "Report", undefined],
  I18N_DOC_FEEDBACK_RPT_DOC_STRING: ["신고", "Report", undefined],
  I18N_COMMENT_TIT: ["승인 요청", "Approval Request", undefined],
  I18N_COMMENT_VIEW_DOC: ["문서보기", "View", undefined],
  I18N_SAVE_LIST: ["내 임시 자료 목록", "My Temporary List", undefined],
  I18N_SAVE_LIST_CONFIRM: ["사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.", "Leave site?\nChanges that you made may not be saved.", undefined],
  I18N_SAVE_LIST_DESC: ["임시 자료는 작성일순 10개 까지만 보관됩니다.", "Max. 10 temporary documents will be saved", undefined],
  I18N_REQUEST_TIT: ["승인 요청", "Approval Request", undefined],
  I18N_DEL_APPR_REQ_TIT: ['삭제 승인 요청', 'Approval Request', undefined],
  I18N_REQUEST_DESC: ["선택한 ‘알림 수신자’에게 승인 요청 메일이 발신됩니다.", "Send the approval request e-mail to the alarm recipient selected.\n\n", undefined],
  I18N_REQUEST_TO: ["알림 수신자", "Alarm Notification Recipient.", undefined],
  I18N_REQUEST_TO_SELECT: ["선택", "Select", undefined],
  I18N_REQUEST_TO_ERROR: ["알림 수신자를 선택해 주십시요.", "Select the option", undefined],
  I18N_REQUEST_COMMENT: ["의견", "Comment", undefined],
  I18N_REQUEST_COMMENT_ERROR: ["의견을 입력해 주세요.", "Write Comment", undefined],
  I18N_REQUEST_PLACEHOLDER: ["200이하 텍스트", "Max. 200 characters", undefined],
  I18N_REJECT_TIT: ["반려", "Reject", undefined],
  I18N_REJECT_REASON: ["사유", "Reason", undefined],
  I18N_REJECT_PLACEHOLDER: ["200이하 텍스트", "Max. 200 characters", undefined],
  I18N_USER_LOGIN_TYPE_SSQ: ["일반 회원", "SSQ", undefined],
  I18N_USER_LOGIN_TYPE_WELS: ["임직원", "Employee", undefined],
  I18N_USER_LOGIN_TYPE_GOOGLE: ["구글", "Google", undefined],
  I18N_USER_LOGIN_TYPE_NAVER: ["네이버", "Naver", undefined],
  I18N_USER_LOGIN_TYPE_KAKAO: ["카카오", "Kakao", undefined],
  I18N_USER_STATE_VERIFIED: ["인증", "Verified", undefined],
  I18N_USER_STATE_NOT_VERIFIED: ["미인증", "Not Verified", undefined],
  I18N_USER_STATE_BLOCKED: ["차단", "Blocked", undefined],
  I18N_USER_DETAIL_NOTIFICATION: ["상세 보기", "Detail", undefined],
  I18N_USER_DETAIL_EMAIL: ["메일 주소", "Email", undefined],
  I18N_USER_NAME: ["성명", "Name", undefined],
  I18N_USER_NICKNAME: ["별명", "Nickname", undefined],
  I18N_USER_PHONE_NUMBER: ["전화 번호", "Phone Number", undefined],
  I18N_USER_MEMBER_TYPE: ["구분", "Job", undefined],
  I18N_USER_MEMBER_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_USER_MEMBER_TYPE_MACHINE_MAKER: ["기계업체", "Machine builder", undefined],
  I18N_USER_MEMBER_TYPE_SMALL_WHOLESALE: ["소/도매", "Wholesaler", undefined],
  I18N_USER_MEMBER_TYPE_SET_MAKER: ["세트메이커", "System Integrator", undefined],
  I18N_USER_MEMBER_TYPE_ELECTRIC_COMPANY: ["전기업체", "Electrical", undefined],
  I18N_USER_MEMBER_TYPE_DEALERSHIP: ["특약점", "Distributor", undefined],
  I18N_USER_MEMBER_TYPE_PANEL_MAKER: ["판넬업체", "Panel maker", undefined],
  I18N_USER_MEMBER_TYPE_STUDENT: ["학생", "Educational Institution", undefined],
  I18N_USER_MEMBER_TYPE_ETC: ["기타", "Etc.", undefined],
  I18N_USER_SCHOOL: ["학교", "Institution", undefined],
  I18N_USER_MAJOR_GRADE: ["학과/학년", "Department", undefined],
  I18N_USER_COMPANY: ["회사", "Company", undefined],
  I18N_USER_DEPARTMENT_TITLE: ["부서/직위", "Department / Job level", undefined],
  I18N_USER_INTEREST_FIELD: ["관심 산업", "Industry", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_RECHARGEABLE_BATTERY: ["2차전지", "Battery", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_PROCESSING_MACHINE: ["가공기", "Machining Center", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_CONSTRUCTION: ["건설", "Construction", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_RUBBER: ["고무", "Rubber / Plastic", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_AIRPORT_AIR: ["공항/항공", "Airport", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_METAL: ["금속", "Metals", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_DISPLAY: ["디스플레이", "Display", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_ROBOT: ["로봇", "Robotics", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_MOBILE: ["모바일", "Mobile", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_CARPENTRY: ["목공", "Wood", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_SEMICONDUCTOR: ["반도체", "Semiconductor", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_DEVELOPMENT: ["발전", "Power", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_FIBER: ["섬유", "Fiber / Textiles", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_WATER_TREATMENT: ["수처리", "Water", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_SMART_FACTORY: ["스마트공장", "Smart Factory", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_FOOD: ["식품", "Food / Beverage", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_ELEVATOR: ["엘리베이터", "Elevator", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_GLASS_CEMENT: ["유리/시멘트", "Glass / Cement", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_MEDICAL_TREATMENT: ["의료", "Healthcare / Bio", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_CAR: ["자동차", "Automotive", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_ELECTRIC: ["전기", "Electric Utility", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_RESTRAINT: ["제지", "Pulp / Paper", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_STEEL: ["철강", "Steel", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_CONVEYOR: ["컨베이어", "Conveyor", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_PACKING_MACHINE: ["포장기", "Packaging", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_PORT_SHIPBUILDING: ["항만/조선", "Marine", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_CHEMISTRY: ["화학", "Chemical", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_ENVIRONMENT: ["환경", "Environment", undefined],
  I18N_USER_INTEREST_FIELD_TYPE_ETC: ["기타", "Etc.", undefined],
  I18N_USER_ACCEPT_TERMS_CONDITIONS: ["이용 약관", "Terms", undefined],
  I18N_USER_ACCEPT_TERMS: ["(필수) 이용 약관 동의", "(Required) Terms and Conditions", undefined],
  I18N_USER_ACCEPT_PRIVATE_ESSENTIAL: ["(필수) 개인정보 처리방침 동의", "(Required) Privacy Policy", undefined],
  I18N_USER_ACCEPT_PRIVATE_ESSENTIAL_DETAIL: ["이용 약관", "Terms", undefined],
  I18N_USER_ACCEPT_PRIVATE_OPTIONAL: ["(선택) 개인정보 처리방침 동의", "(Optional) Privacy Policy", undefined],
  I18N_USER_ACCEPT_PRIVATE_OPTIONAL_DETAIL: ["이용 약관", "Terms", undefined],
  I18N_USER_ACCEPT_MAIL: ["(선택) 메일 수신 동의", "(Optional) Promotional Communications", undefined],
  I18N_USER_ACCEPT_MARKETING_ALLOW: ["(선택) 마케팅 활용 동의", "(Optional) Privacy Policy", undefined],
  I18N_USER_ACCEPT_MAIL_NOTIFICATION: ["수신 동의 시 LS ELECTRIC 뉴스레터를 메일로 보내드립니다.", "Subscribe to LS ELECTRIC Newsletter", undefined],
  I18N_TERMS_DETAIL_POPUP_TERMS: ["이용 약관", "Terms and Conditions", undefined],
  I18N_TERMS_DETAIL_POPUP_EVENT: ["개인정보 처리방침", "Privacy Policy", undefined],
  I18N_USER_CONFIRM: ["확인하기", "Confirm", undefined],
  I18N_LOG_SEARCH_RESULT: ["조회 결과", "Result", undefined],
  I18N_LOG_SEARCH_REST: ["검색 옵션 초기화", 'Clear Filter', undefined],
  I18N_LOG_DATE: ["기간", "Period", undefined],
  I18N_LOG_KEYWORD: ["키워드", "Keyword", undefined],
  I18N_LOG_KEYWORD_PLACEHOLDER: ["별명 / 성명 / 로그", "Nickname / User / Log", undefined],
  I18N_LOG_NICKNAME_NAME: ["별명(성명)", "Nickname(Name)", undefined],
  I18N_LOG_MENU_TYPE: ["메뉴", "Menu Type", undefined],
  I18N_LOG_ACTION_TYPE: ["액션", "Action", undefined],
  I18N_LOG_LITERAL_TYPE: ["로그", "Log", undefined],
  I18N_LOG_TIME: ["로그 시각", "Log Time", undefined],
  I18N_MENU_TYPE_DOCUMENT: ["문서 관리", "Document Management", undefined],
  I18N_MENU_TYPE_TECH_DOCUMENT: ["기술문서 관리", "Tech Document Management", undefined],
  I18N_MENU_TYPE_COMMUNITY: ["커뮤니티 관리", "Community Management", undefined],
  I18N_MENU_TYPE_URL: ["URL 관리", "URL Management", undefined],
  I18N_MENU_TYPE_CATEGORY: ["카테고리 관리", "Category Management", undefined],
  I18N_MENU_TYPE_USER: ["사용자 관리", "User Management", undefined],
  I18N_MENU_TYPE_FEEDBACK: ["피드백 관리", "Feedback Management", undefined],
  I18N_MENU_TYPE_POPUP_SETTING: ['메뉴 팝업관리', 'Popup Management', undefined],
  I18N_ACTION_TYPE_DELETE: ["삭제", "Delete", undefined],
  I18N_ACTION_TYPE_APPROVAL_REQUEST: ["승인 요청", "Approval Request", undefined],
  I18N_ACTION_TYPE_DEL_REQUEST: ["삭제 요청", "Delete Request", undefined],
  I18N_ACTION_TYPE_REJECT: ["반려", "Reject", undefined],
  I18N_ACTION_TYPE_REJECT_DEL: ["삭제 반려", "Reject Delete Request", undefined],
  I18N_ACTION_TYPE_APPROVAL: ["승인", "Approval", undefined],
  I18N_ACTION_TYPE_UPDATE: ["수정", "Update", undefined],
  I18N_ACTION_TYPE_SAVE: ["저장", "Save", undefined],
  I18N_ACTION_TYPE_FEEDBACK_CONFIRM: ["조치 완료", "Feedback Confirm", undefined],
  I18N_ACTION_TYPE_CATEGORY_ADDITION: ["카테고리 추가", "Category Addition", undefined],
  I18N_ACTION_TYPE_UPDATE_ORDERING: ["순서 변경", "Update Ordering", undefined],
  I18N_ACTION_TYPE_EXPORT_ALL_USER: ["모든 사용자 CSV 추출", "Export CSV file for All Users", undefined],
  I18N_ACTION_TYPE_EXPORT_ALLOW_MARKETING_USER: ["마케팅 허용 사용자 CSV 추출", "Export CSV file for Allow Marketing Users", undefined],
  I18N_ACTION_TYPE_POPUP_CENTER_ADD: ['중앙팝업 - 등록', 'Center popup - Save', undefined],
  I18N_ACTION_TYPE_POPUP_CENTER_MODIFY: ['중앙팝업 - 수정', 'Center popup - Modify', undefined],
  I18N_ACTION_TYPE_POPUP_CENTER_REMOVE: ['중앙팝업 - 삭제', 'Center popup - Delete', undefined],
  I18N_ACTION_TYPE_POPUP_BOTTOM_ADD: ['하단팝업 - 등록', 'Bottom popup - Save', undefined],
  I18N_ACTION_TYPE_POPUP_BOTTOM_MODIFY: ['하단팝업 - 수정', 'Bottom popup - Modify', undefined],
  I18N_ACTION_TYPE_POPUP_BOTTOM_REMOVE: ['하단팝업 - 삭제', 'Bottom popup - Delete', undefined],
  I18N_ACTION_TYPE_POPUP_ASSET_ADD: ['Asset팝업 - 등록', 'Asset popup - Save', undefined],
  I18N_ACTION_TYPE_POPUP_ASSET_MODIFY: ['Asset팝업 - 수정', 'Asset popup - Modify', undefined],
  I18N_ACTION_TYPE_POPUP_ASSET_REMOVE: ['Asset팝업 - 삭제', 'Asset popup - Delete', undefined],
  I18N_NO_DOCUMENT: ["조회된 자료가 없습니다.", "No result found.", undefined],
  I18N_NO_DOCUMENT_NOTICE: ["조회 필터를 설정해 주세요.", "Choose different filters", undefined],
  I18N_PREVIEW_PRODUCT_CATEGORY: ["제품 카테고리", "Product Category", undefined],
  I18N_PREVIEW_INDUSTRY_CATEGORY: ["산업 카테고리", "Industry Category", undefined],
  I18N_PREVIEW_APPLICATION_LOAD_CATEGORY: ["적용 부하 카테고리", "Application & Load Category", undefined],
  I18N_PREVIEW_FEEDBACK: ["이 자료가 도움이 되었나요?", "Was this helpful?", undefined],
  I18N_PREVIEW_FEEDBACK_GOOD: ["네", "Helpful", undefined],
  I18N_PREVIEW_FEEDBACK_BAD: ["아니오", "Not Helpful", undefined],
  I18N_PREVIEW_HELPFUL: ["도움돼요", "Helpful", undefined],
  I18N_PREVIEW_NOT_HELPFUL: ["부족해요", "Not Helpful", undefined],
  I18N_PREVIEW_DOWNLOAD: ["다운로드", "Download", undefined],
  I18N_PREVIEW_CLICK_COUNT: ["조회수", "Views", undefined],
  I18N_PREVIEW_EMPLOYEE: ["임직원", "Employee", undefined],
  I18N_PREVIEW_DOC_DOWNLOAD_COUNT: ["다운로드 수", "Download Count", undefined],
  I18N_PREVIEW_DOC_COL_VERSION: ["버전", "Version", undefined],
  I18N_PREVIEW_TITLE: ["미리보기", "Preview", undefined],
  I18N_FILE_LANG_KO: ["한국어", "Korean", undefined],
  I18N_FILE_LANG_EN: ["영어", "English", undefined],
  I18N_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_BOARD: ["게시판", "Board", undefined],
  I18N_ADMIN_CMMNTY_DIVISION: ["구분", "Category", undefined],
  I18N_ADMIN_CMMNTY_KIND: ["종류", "Type", undefined],
  I18N_ADMIN_CMMNTY_OPEN: ["공개", "Public", undefined],
  I18N_ADMIN_CMMNTY_RECOMMEND: ["추천", "Recommend", undefined],
  I18N_ADMIN_CMMNTY_FEEDBACK: ["피드백", "Feedback", undefined],
  I18N_ADMIN_CMMNTY_FINISHED: ["완료", "Completed", undefined],
  I18N_ADMIN_CMMNTY_NOT_FINISHED: ["진행중", "Progress…", undefined],
  I18N_ADMIN_CMMNTY_KEYWORD: ["키워드", "Keyword", undefined],

  I18N_ADMIN_SW_DIVISION: ["구분", "SW", undefined],
  I18N_ADMIN_SW_NAME: ["SW 명", "SW Name", undefined],
  I18N_ADMIN_SW_PIN_VERSION: ["PIN 버전", "Pin Version", undefined],
  I18N_ADMIN_SW_UPDATED_DATE: ["업데이트", "Update", undefined],
  I18N_ADMIN_SW_INPUT: ["제목", "SW Name", undefined],
  I18N_ADMIN_SW_PLC: ["PLC", "PLC", undefined],
  I18N_ADMIN_SW_DRIVE: ["Drive", "Drive", undefined],
  I18N_ADMIN_SW_HMI: ["HMI", "HMI", undefined],
  I18N_ADMIN_SW_SELECTOR: ["Selector", "Selector", undefined],
  I18N_ADMIN_SW_ASSET_DRIVE: ["Asset Drive", "Asset Drive", undefined],
  I18N_ADMIN_SW_ETC: ["기타", "ETC", undefined],
  I18N_ADMIN_SW_REGIST: ["등록", "Register", undefined],
  I18N_ADMIN_SW_PLACEHOLDER: ["SW명 (영어, 30자 이하 텍스트)", "Software Name(English, Max. 30 Text)", undefined],
  I18N_ADMIN_SW_REGIST_BUTTON_SAVE: ["상단 등록 버튼을 이용해 수정사항을 저장해 주세요.", "Please save your modifications using the Register button.", undefined],
  I18N_ADMIN_SW_VERSION_NAME: ["버전명을 입력하세요", "Enter the text name of the version.", undefined],
  I18N_ADMIN_SW_VERSION_FILE_ADD: ["SW 파일을 첨부해 주세요.", "Please attach the SW file", undefined],
  I18N_ADMIN_SW_DESC_PLACEHOLDER: ["설명 (200자 이내 텍스트)", "Description (Max. 200 characters)", undefined],
  I18N_ADMIN_SW_ALARM_MAIL: ["알림 수신", "Notification", undefined],
  I18N_ADMIN_SW_TITLE_WARN_NODATA: ["30자 이하의 텍스트를 입력해 주세요.", "Enter the text below 30 characters.", undefined],
  I18N_ADMIN_SW_TITLE_WARN_DISTINCT: ["사용중인 소프트웨어 이름이 있습니다.", "The software name is in use.", undefined],
  I18N_ADMIN_SW_PIN_NOTI: ["핀을 지정하여 최신 버전을 관리하세요", "Update to the latest version by registering a pin.", undefined],
  I18N_ADMIN_SW_PIN_NOTI_DESC: ["‘ , ’로 구분하여 이메일 알림 수신자를 등록하면, 버전 등록시 버전과 업데이트 내용이 등록한 수신자 메일로 발송됩니다.", "If you register e-mail notification recipients separated by ' , ', the version and updates will be sent to the registered recipients when the version is updated.", undefined],
  I18N_ADMIN_SW_PIN_NOTI2: ["핀으로 지정하여 등록하기", "Registering by pinning.", undefined],
  I18N_ADMIN_SW_VS_WARN: ["숫자와 ‘ . ’만 입력해 주세요.", "Only number and '.'", undefined],
  I18N_ADMIN_SW_VERSION_REGIST_ERROR: ['버전등록이 되지 않았습니다. 버전 등록 버튼을 이용해 등록해 주세요.',
    'Your version is not registered, please register using the Register Version button.', undefined],
  I18N_ADMIN_SW_SEARCH: ["찾기", "Upload", undefined],
  I18N_ADMIN_SW_REGIST_LOADING1: ["파일 업로드 중입니다.", "Uploading a file…", undefined],
  I18N_ADMIN_SW_REGIST_LOADING2: ["잠시만 기다려주세요.", "Wait a moment.", undefined],
  I18N_ADMIN_SW_DELETE_PIN_VERSION: ["핀으로 등록된 버전입니다. 삭제 시 핀이 사라집니다.", "This is a pinned version. If you delete it, the pin will disappear", undefined],
  I18N_ADMIN_SW_PLACEHOLDER_VERSION: ["버전 (ex 1.1.1)", "Version (ex 1.1.1)", undefined],
  I18N_ADMIN_SW_PLACEHOLDER_VERSION_DESC: ["업데이트 내용 (300자 이내 텍스트)", "Updates (Max. 300 characters)", undefined],
  I18N_ADMIN_SW_NOTUPLOADED: ["문서 등록 시 파일이 업로드 됩니다.", "The file will be uploaded when you register the document.", undefined],
  I18N_ADMIN_SW_TYPE_WARN: ["타입을 지정해주세요.", "Select the software type.", undefined],
  I18N_ADMIN_SW_SELECTOR_NM_REQUIRED: ['소프트웨어 명을 입력하세요', 'Enter the name of the software', undefined],
  I18N_ADMIN_SW_SELECTOR_FILE_ERROR: ['파일 형식을 확인해주세요', 'Please check the file type', undefined],
  I18N_ADMIN_SW_FILTER_OPTION: ["자료", "SW", undefined],
  I18N_ADMIN_CANNOT_FIND_LOGIN: ["로그인 정보를 불러오는 중입니다. 잠시 뒤 시도해주세요.", "Loading your login information. Try it later", undefined],
  I18N_ADMIN_CONFIRM_DELETE: ["삭제 하시겠습니까?", "Do you want to delete?", undefined],
  I18N_ADMIN_EXIST_URL_GROUP: ["사용중인 URL 그룹 있습니다.", "Exist using URL group.", undefined],
  I18N_ADMIN_NOT_SELECTED_ALERT: ["항목을 선택해주세요.", "Please select a list.", undefined],
  I18N_ADMIN_DELETE_SUCCESS: ['삭제가 완료되었습니다.', "Delete completed.", undefined],
  I18N_FILTER_PLACEHOLDER: ["내용 / 작성자 / 대상자 / 자료명", "Comment / Writer / User / Document", undefined],
  I18N_WRITER_TAG_NOTICE: ['100개 이상의 태그 또는 카테고리를 선택할 수 없습니다.', 'You cannot select more than 100 tags or categories.', undefined],
  I18N_CATEGORY_LIMIT_COUNT: ["카테고리는 100개만 추가 가능합니다.", "Max. 100 categories can be created.", undefined],
  I18N_ADMIN_COMPANION_NOTICE: ["반려되었습니다. 현재 창을 닫겠습니까?", "Rejected. Do you want to close this window?", undefined],
  I18N_ADMIN_APPROVAL_NOTICE: ["승인되었습니다. 현재 창을 닫겠습니까?", "Approved. Do you want to close this window?", undefined],
  I18N_ADMIN_COMPANION_DELETE: ["반려된 글이 삭제됩니다.", "The rejected article will be deleted.", undefined],
  I18N_ORIGINAL_POST_DELETE: ['원본 자료가 삭제 됩니다.', 'The original file will be deleted.', undefined],
  I18N_DELETE_CONFIRM: ["정말로 삭제하시겠습니까?", "Are you sure you want to delete it?", undefined],
  I18N_DELETE_SUCCESS: ["삭제에 성공하였습니다.", "Deleted successfully.", undefined],
  I18N_DELETE_FAIL: ['삭제에 실패하였습니다.', "Delete failed.", undefined],
  I18N_EDIT_POST_DELETE: ["편집된 내용이 삭제됩니다.", "The edited content is deleted.", undefined],
  I18N_TEMPORARY_SAVE_FAIL: ["임시 저장에 실패하였습니다.", "Failed to save.", undefined],
  I18N_TEMPORARY_SAVE_OK: ["임시 저장 되었습니다.", "Save completed.", undefined],
  I18N_WRITE_TEMP_DELETE: ["해당하는 임시 저장 문서를 삭제하시겠습니까?", "Are you sure you want to delete this temporary document?", undefined],
  I18N_DOCWRITE_SELECT_IMAGE_FILE: ["이미지 파일을 선택하여 주십시오.", "Please select an image file.", undefined],
  I18N_DOCWRITE_SAMPLE_DESC: ["샘플 설명(50자 이하 텍스트)", "Description (Max. 50 Text)", undefined],
  I18N_DOCWRITE_WRITER_ONLY: ["나만보기", "Only show my contents", undefined],
  I18N_DOCWRITE_SAMPLE_VER_PLACEHOLDER: ["버전 (ex 1.0)", "Version (ex 1.0)", undefined],
  I18N_DOCWRITE_SAMPLE_VER_DESC: ["업데이트시 이전 버전이상으로 입력하여야 합니다.", "You must register a higher version when updating.", undefined],
  I18N_DOCWRITE_SAMPLE_TITLE: ['제목', "Title", undefined],

  I18N_DOCWRITE_NEW_TITLE: ['새 글쓰기', 'New', undefined],
  I18N_DOCWRITE_NEW_SUBTITLE: ['신규 문서로 등록합니다.', 'Register as a new document.', undefined],

  I18N_DOCWRITE_MOD_TITLE: ['수정하기', 'Modify', undefined],
  I18N_DOCWRITE_MOD_SUBTITLE: ['기존 문서에 덮어 씁니다.', 'Overwrites an existing document.', undefined],

  I18N_DOCWRITE_APPLY_TEMPL: ['템플릿 적용', 'Template', undefined],
  I18N_DOCWRITE_REGIST_TIME: ['자료 작성일', 'Registraion', undefined],

  I18N_DOCWRITE_INQUIRY_CATEGORY: ['제품', 'InquiryType', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE: ['문의 유형', 'InquiryCategory', undefined],

  I18N_DOCWRITE_INQUIRY_TYPE_TECH: ['기술 문의', 'Technical', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE_SALES: ['영업 문의', 'Sales', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE_SERVICE: ['서비스 문의', 'Service', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE_ETC: ['기타 문의', 'Others', undefined],

  I18N_DOCWRITE_INQUIRY_TYPE_PLC: ['PLC', 'PLC', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE_DRIVE: ['Drive', 'Drive', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE_SERVO: ['Servo', 'Servo', undefined],
  I18N_DOCWRITE_INQUIRY_TYPE_HMI: ['HMI', 'HMI', undefined],

  I18N_DOCWRITE_PARTNER_DOC_STATUS_OPEN: ['접수', 'Registered', undefined],
  I18N_DOCWRITE_PARTNER_DOC_STATUS_PROCESSING: ['진행', 'In Process', undefined],
  I18N_DOCWRITE_PARTNER_DOC_STATUS_DELAYED: ['지연', 'Delay', undefined],
  I18N_DOCWRITE_PARTNER_DOC_STATUS_CLOSED: ['완료', 'Completed', undefined],

  I18N_FILE_TYPE_NONE: ["유형", "Type", undefined],
  I18N_FILE_TYPE_SAMPLE: ["샘플", "Sample", undefined],
  I18N_FILE_TYPE_LIBRARY: ["라이브러리", "Library", undefined],
  I18N_FILE_TYPE_TEMPLATE: ["템플릿", "Template", undefined],

  I18N_TARGET_SW_TYPE_NONE: ["소프트웨어", "Software", undefined],
  I18N_TARGET_SW_TYPE_XG5000: ["XG5000", "XG5000", undefined],
  I18N_TARGET_SW_TYPE_XP_BUILDER: ["XP-Builder", "XP-Builder", undefined],
  I18N_TARGET_SW_TYPE_DRIVEVIEW: ["DriveView", "DriveView", undefined],
  I18N_DOCWRITE_HIST_INS_ERROR: ["이력이 제대로 삽입되지 않았습니다.", "The history has not been registered properly.", undefined],
  I18N_DOCWRITE_WRITE: ["작성", "Write", undefined],
  I18N_DOCWRITE_UPDATE: ["수정", "Modify", undefined],
  I18N_USER_FIRST_NAME: ["이름", "First name", undefined],
  I18N_USER_LAST_NAME: ["성", "Last name", undefined],

  I18N_URL_CHECK_DATA: ['데이터를 확인하여 주십시오', "Please check the data", undefined],
  I18N_URL_MODIFY_FAIL: ['변경에 실패했습니다. 다시 시도해주세요.', "Update failed, please try again", undefined],
  I18N_URL_MODIFY_CHECK: ['정말 선택한 항목을 수정하시겠습니까?', "Do you want to modify the selected address?", undefined],
  I18N_URL_FILE_CLICK: ['파일을 선택하여 주십시오.', "Please select a file.", undefined],
  I18N_URL_MAX_TEXT: ['10자 이내 영문,숫자만', "Max 10 alphanumeric", undefined],
  I18N_URL_MAX_TEXT2: ['15자 이내 영문, 숫자만', "Max 15 alphanumeric", undefined],
  I18N_URL_MAX_TEXT3: ["32자 이내 영문, 숫자, '+','-','_'", "32 alphanumeirc '+','_','-'", undefined],
  I18N_URL_ID_ALERT: ['중복된 ID입니다.', "Duplicate ID", undefined],
  I18N_URL_EXCEL_IMPORT: ['엑셀 불러오기', "Import CSV", undefined],
  I18N_URL_EXCEL_EXPORT: ['엑셀 내보내기', 'Export CSV', undefined],
  I18N_URL_GROUP_MANAGEMENT: ['그룹 관리', 'Group', undefined],
  I18N_URL_PRODUCT: ['제품군', "Product", undefined],
  I18N_URL_GROUP: ['그룹', 'Group', undefined],
  I18N_URL_COUNTRY: ['국가', "Country", undefined],
  I18N_URL_ID: ['ID', "ID", undefined],
  I18N_URL_ADDRESS: ['생성주소', "Source URL", undefined],
  I18N_URL_ORIGINAL_ADDRESS: ["원본주소", "Destination URL", undefined],
  I18N_URL_UPDATE: ['업데이트', "Update", undefined],
  I18N_URL_MODIFY: ["수정자", "Modifier", undefined],
  I18N_URL_FILTER_PLACEHOLDER: ['그룹명,ID, URL', "Group, ID, URL", undefined],
  I18N_URL_GROUP_RESULT: ['조회결과', 'Result', undefined],
  I18N_URL_GROUP_NAME: ['그룹명', "Group", undefined],
  I18N_URL_GROUP_DELETE: ["일괄 삭제", "Delete", undefined],

  I18N_URL_GROUP_FILE_EXTENSION: ["파일 형식을 확인해주세요", "Please check the file type", undefined],
  I18N_URL_GROUP_EXCEL_UPDATE: ['데이터를 불러왔습니다.', "The data has been loaded.", undefined],
  I18N_URL_GROUP_EXCEL_UPDATE_FAILED: ['불러오는 값이 없습니다. 파일을 확인해주세요.', "No values are being imported, please check your file", undefined],
  I18N_URL_GROUP_EXCEL_EMPTY_ALERT: ['기존과 동일한 내용입니다. 파일을 확인해 주세요', "It's the same as before. Please check the file", undefined],
  I18_URL_GROUP_EXCEL_DUPLICATION: ['데이터 중복', "Data Redundancy", undefined],
  I18N_URL_GROUP_EXCEL_WRONG_COUNTRY: ['잘못된 국가명', "Invaild country code", undefined],
  I18N_URL_GROUP_EXCEL_WRONG_GROUP: ['잘못된 URL그룹', "Invaild URL group", undefined],
  I18N_URL_GROUP_EXCEL_DISMATCH_GROUP: ['URL 그룹, 그룹명 불일치', 'Invalid URL group, title', undefined],
  I18N_URL_GROUP_EXCEL_ERRORMESSAGE: ['오류가 발견되어 불러오기에 실패했습니다.', 'The import failed with errors', undefined],
  I18N_URL_GROUP_EXCEL_ERROR: ['에러가 발생했습니다', 'An error occurred', undefined],
  I18N_URL_GROUP_EXCEL_ERROR_SAME: ['중복값이 있습니다. 파일을 확인해 주세요.', 'There are duplicate values.', undefined],
  I18N_URL_GROUP_EXCEL_ERROR_EMPTY: ['빈 값이 있습니다. 다시 한번 파일을 확인해 주세요.', 'There is an empty value. Please check your file.', undefined],

  I18N_POPUP_CENTER: ["중앙 팝업", "Center popup", undefined],
  I18N_POPUP_BOTTOM: ["하단 팝업", "Bottom popup", undefined],
  I18N_POPUP_ASSET: ["Asset 팝업", "Asset popup", undefined],

  I18N_POPUP_ADD: ["+ 팝업 추가", "+ Add", undefined],

  I18N_POPUP_GUEST: ["게스트", "Guest", undefined],
  I18N_POPUP_LOGIN: ["로그인", "Login User", undefined],
  I18N_POPUP_PARTNER: ["파트너", "Partner", undefined],
  I18N_POPUP_EMPLOYEE: ["임직원", "Employee", undefined],
  I18N_POPUP_LABORATORY: ["연구소", "R&D only", undefined],

  I18N_POPUP_DISABLE_DAY: ['오늘 하루 보지 않기', "Don't show again today", undefined],
  I18N_POPUP_DISABLE_WEEK: ['일주일 보지 않기', "Don't show again for 7 days", undefined],
  I18N_POPUP_DISABLE_NEVER: ['다시 보지 않기', "Don't show again", undefined],

  I18N_POPUP_TITLE: ["팝업 명", "Popup Name", undefined],
  I18N_POPUP_TYPE: ["대상", "Target", undefined],
  I18N_POPUP_DATE: ["게시기간", "Period", undefined],
  I18N_POPUP_TEXT: ["문구", "Text", undefined],
  I18N_POPUP_ALERT_WRONGDATE: ["잘못된 날짜입니다.", "Wrong DateTime.", undefined],

  I18N_POPUP_DISABLE_OPTION: ["팝업 끄기 옵션", "Option for close", undefined],

  I18N_POPUP_TARGET_LINK: ["배경 타겟 링크", "Link of background image", undefined],
  I18N_POPUP_TARGET_LINK_PLACE: ["타겟 링크 (www.ls-electric.com)", "Link URL (www.ls-electric.com)", undefined],

  I18N_POPUP_BUTTON: ["버튼", "Button", undefined],

  I18N_POPUP_MAX_20: ["20자 이내 텍스트", "20 characters or less", undefined],
  I18N_POPUP_MAX_30: ["30자 이내 텍스트", "30 characters or less", undefined],
  I18N_POPUP_TEXT_COLOR: ["텍스트색 (기본 #2A2C2F)", "Color of Text(default #2A2C2F)", undefined],
  I18N_POPUP_BUTTON_COLOR: ["버튼색 (기본 #fffff)", "Color of Button(default #fffff)", undefined],


  I18N_POPUP_NOT_SELECTED: ["선택하지 않은 팝업입니다.", "Unchecked popup.", undefined],
  I18N_POPUP_DELETE_SUCCESS: ["선택한 팝업 정보를 삭제합니다.", "Delete the selected popup information.", undefined],
  I18N_POPUP_DELETE_FAIL: ["팝업 삭제에 실패했습니다.", "Popup deletion failed.", undefined],
  I18N_POPUP_CHANGE_SEQUENCE_FAIL: ["순서변경에 실패했습니다.", "Reorder failed.", undefined],
  I18N_POPUP_ALERT_WRONG_EXTENSTION: ["png 또는 jpg의 이미지 파일으로 업로드 해주세요.", "Please upload as an image file .png or .jpg ", undefined],
  I18N_POPUP_ALERT_LARGE_FILESIZE: ["10MB 이하의 이미지 파일으로 업로드 해주세요.", "Please upload as an image file no larger than 10MB.", undefined],
  I18N_POPUP_ADD_BUTTON: ["버튼 추가", "Add a button", undefined],

  I18N_POPUP_DISPLAY_POSITION: ["게시 위치", "Publish to", undefined],
  I18N_POPUP_DISPLAY_POSITION_CENTER_ALL: ["전체 페이지용", "All Pages", undefined],
  I18N_POPUP_DISPLAY_POSITION_CENTER_MAINPAGE: ["메인 페이지 전용", "Main Page Only", undefined],

  I18N_FEEDBACK_SHOW_EMPTY_COMMENT: ['내용 없는 피드백 보기', "Show blank feedback", undefined],
  I18N_DOC_WRITE_SERVICE_LINK: ['서비스 링크', "Service Link", undefined],
  I18N_DOC_WRITE_SERVICE_LINK2: ['서비스 링크2', "Service Link2", undefined],
  I18N_SESSION_INVALIDATE: ['세션이 만료되었습니다.', 'Your session has expired.', undefined],
  I18N_DOC_WRITE_SERVICE_PLACEHOLDER: ['링크명(10자 이내 텍스트)', "Link name Max. 15 characters", undefined],
  I18N_CATEGORY_CHILD_EXPOSE_ERR: ['하위 카테고리가 공개 입니다. 비공개로  처리해 주세요', 'The sub-category is public. Please make it private', undefined],
  TEST1: ['테스트1', "TEST1", 'TEST1 US'],
  TEST2: ['테스트2', "TEST2", undefined],

  I18N_TAB_PARTNER: ['파트너 관리', 'Partner', undefined],
  I18N_PARTNER_MANAGE_ADD: ['파트너 추가', 'Add a partner', undefined],
  I18N_PARTNER_MANAGE_DEFAULT_MANAGERS_SET: ['담당자 미지정 파트너 담당자 설정', 'Set default contact managers', undefined],
  I18N_PARTNER_MANAGE_DEFAULT_MANAGERS: ['미지정 파트너 담당자', 'Default contact managers', undefined],
  I18N_PARTNER_MANAGE_DEFAULT_ADD_NAME: ["새 파트너", "New Partner", undefined],
  I18N_PARTNER_MANAGE_NAME: ["파트너명", "Partner Name", undefined],
  I18N_PARTNER_MANAGE_WORKING: ["협력중", "Working", undefined],
  I18N_PARTNER_MANAGE_EXPIRED: ["미협력", "Expired", undefined],
  I18N_PARTNER_MANAGE_ADD_EMAIL: ["파트너 계정", "Add Partners with E-mail ID", undefined],
  I18N_PARTNER_MANAGE_ADD_EMAIL_PLACEHOLDER: ["이메일ID로 추가", "Enter the E-mail ID", undefined],
  I18N_PARTNER_MANAGE_LIST_EMAIL: ["이메일ID", "E-mail ID", undefined],
  I18N_PARTNER_MANAGE_LIST_PARTNER_NAME: ["파트너", "Name", undefined],
  I18N_PARTNER_MANAGE_LIST_COMPANY: ["회사", "Company", undefined],
  I18N_PARTNER_MANAGE_LIST_LEVEL: ["직급", "Department", undefined],
  I18N_PARTNER_MANAGE_ADD_NAME_PLACEHOLDER: ["성명 또는 이메일로 추가", "Employee name or e-mail.", undefined],
  I18N_PARTNER_MANAGE_LIST_NAME: ["성명", "Name", undefined],
  I18N_PARTNER_MANAGE_LIST_TEAM: ["부서", "Department", undefined],
  I18N_PARTNER_MANAGE_MANAGERS: ['문의 담당자', 'Owner', undefined],
  I18N_PARTNER_MANAGE_MANAGERS_SET_ROLE: ['담당 범위', 'Set the Role of Owner', undefined],
  I18N_PARTNER_MANAGE_INQUIRY_TYPE: ['문의 유형', 'Type', undefined],
  I18N_PARTNER_MANAGE_OTHERS: ['기타', 'Others', undefined],
  I18N_PARTNER_MANAGE_INQUIRY_TYPE_TECH: ['기술 문의', 'Technical', undefined],
  I18N_PARTNER_MANAGE_INQUIRY_TYPE_SALES: ['영업 문의', 'Sales', undefined],
  I18N_PARTNER_MANAGE_INQUIRY_TYPE_SERVICE: ['서비스 문의', 'Service', undefined],
  I18N_PARTNER_MANAGE_INQUIRY_TYPE_ETC: ['기타 문의', 'Others', undefined],
  I18N_PARTNER_MANAGE_CHIP_MAIN: ['정', 'Main', undefined],
  I18N_PARTNER_MANAGE_CHIP_SUB: ['부', 'Sub', undefined],
  I18N_PARTNER_QNA_SEARCH_RESULT: ["조회 결과", "Result", undefined],
  I18N_PARTNER_QNA_SEARCH_REST: ["초기화", 'Clear Filter', undefined],
  I18N_PARTNER_QNA_PRODUCT: ["제품", 'Product', undefined],
  I18N_PARTNER_QNA_TYPE: ["유형", 'Type', undefined],
  I18N_PARTNER_QNA_SELECT: ["선택", "Select", undefined],
  I18N_PARTNER_QNA_DATE: ["기간", "Period", undefined],
  I18N_PARTNER_QNA_KEYWORD: ["키워드", "Keyword", undefined],
  I18N_PARTNER_QNA_KEYWORD_PLACEHOLDER: ["내용 / 작성자 / 대상자 / 자료명", "Enquiry / Write / Contact", undefined],
  I18N_PARTNER_QNA_EXCEL_EXPORT: ['엑셀 내보내기', 'Export CSV', undefined],
  I18N_PARTNER_QNA_TABLE_STATUS: ["상태", 'Product', undefined],
  I18N_PARTNER_QNA_TABLE_EXPOSE: ["공개", 'Visibility', undefined],
  I18N_PARTNER_QNA_TABLE_SITE: ["사이트", 'Site', undefined],
  I18N_PARTNER_QNA_TABLE_TYPE: ["구분", 'Type', undefined],
  I18N_PARTNER_QNA_TABLE_ENQUIRY: ["내용", 'Enquiry', undefined],
  I18N_PARTNER_QNA_TABLE_DAY_OPEN: ["작성일", 'Open', undefined],
  I18N_PARTNER_QNA_TABLE_DAY_CLOSE: ["완료일", 'Closed', undefined],
  I18N_PARTNER_QNA_TABLE_DAY_SPENT: ["소요일", 'Days Spent', undefined],
  I18N_PARTNER_QNA_TABLE_WRITER: ["작성자", 'Writer', undefined],
  I18N_PARTNER_QNA_TABLE_PARTNER: ["파트너", 'Partner', undefined],
  I18N_PARTNER_QNA_TABLE_CONTACT: ["담당자", 'Contact', undefined],
  I18N_PARTNER_QNA_TABLE_RATE: ["별점", 'Rate', undefined],
  I18N_PARTNER_CHANGE_SEQ: ["순서 변경 실패", "You can't change the display order.", undefined],
  I18N_PARTNER_QNA_DELAY: ["지연", "Delay", undefined],
  I18N_PARTNER_REGIST_ALREADY_OTHER_PARTNER: ["다른 파트너사에 추가된 ID 입니다.", "It is already registered with another partner.", undefined],
  I18N_PARTNER_REGIST_ALREADY: ["이미 등록된 파트너 계정입니다.", "It is already registered.", undefined],
  I18N_PARTNER_REGIST_ALREADY_MANAGER: ["이미 등록된 파트너 담당자입니다.", "It is an already registered with owner.", undefined],
};


export type RES_ADMIN = keyof typeof resi18n_admin_page | RES_ALL;
const [ko, en, enus] = resi18nutil.getres(resi18n_admin_page);

export const resi18n_admin_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_admin_page_en = {...resi18n_all_en, ...en};
export const resi18n_admin_page_enus = {...resi18n_all_enus, ...enus};
